import React, { useEffect, useState } from "react";
import {
  CardElement,
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import OrderService from "../service/OrderService";
import { useHistory } from "react-router-dom";

export default function CheckoutForm() {
  const history = useHistory();
  const stripe = useStripe();
  const elements = useElements();
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorStatus, setErrorStatus] = useState("");

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          toast.success("Payment succeeded!");
          break;
        case "processing":
          setMessage(
            <h5 style={{ color: "#FF0000" }}>Your payment is processing.</h5>
          );
          break;
        case "requires_payment_method":
          setMessage(
            <h5 style={{ color: "#FF0000" }}>
              Your payment was not successful, please try again.
            </h5>
          );
          break;
        default:
          setMessage(
            <h5 style={{ color: "#FF0000" }}>Something went wrong.</h5>
          );
          break;
      }
    });
  }, [stripe]);

  // useEffect(() => {
  //   createOrder();
  // }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);
    localStorage.setItem("paymentredirect", true)
    const { error } = await stripe.confirmPayment({
      elements,

      confirmParams: {
        return_url: process.env.REACT_APP_URL + "payment_redirect",
        // return_url: "http://localhost:3011/payment_redirect",
        // return_url: elements.getElement(CardElement),
      },
    });

    setIsLoading(false);
    if (error) {
      // setErrorStatus(error);
      toast.error(error.code.replace(/_/g, ' '));
      return false;
    }
  };


  return (
    <>
      {isLoading ? (
        <div className="fullloading text-center">
          <Spinner animation="grow" variant="light" />
        </div>
      ) : (
        ""
      )}
      {!success ? (
        <div>
          <form id="payment-form">
            <PaymentElement id="payment-element" />
            <button
              disabled={isLoading || !stripe || !elements}
              id="submit"
              type="button"
              className="btn btn-primary home-btn"
              onClick={handleSubmit}
            >
              {!isLoading && "Pay Now"}
              {isLoading && (
                <span
                  className="indicator-progress"
                  style={{ display: "block" }}
                >
                  Please wait...{" "}
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </button>
            {message && <div id="payment-message">{message}</div>}
          </form>
        </div>
      ) : (
        ""
      )}
    </>
  );
}


// const getUser = JSON.parse(localStorage.getItem("byforyourData"));
// const getInitial = JSON.parse(localStorage.getItem("initialData"));
// const cardName = localStorage.getItem("card_name");
// const getUsergifts = JSON.parse(localStorage.getItem("gitafriendData"));
// const getInitialgifts = JSON.parse(localStorage.getItem("initialDatagift"));

// const createOrder = () => {
//   let postData = {};
//   if (getUser) {
//     postData = {
//       ...postData,
//       ...getUser,
//     };
//   }
//   if (getUsergifts) {
//     postData = {
//       ...postData,
//       ...getUsergifts,
//     };
//   }
//   if (getInitial) {
//     postData = {
//       amount: getInitial.amount,
//       payment_method: "Stripe",
//       card_name: cardName,
//       style_code: "lorem",
//       pg_txn_id: "NULL",
//       // pg_api_response: JSON.stringify(errorStatus),
//       pg_status: "FAILED",
//       ...getInitial,
//       ...postData,
//     };
//   }
//   if (getInitialgifts) {
//     postData = {
//       amount: getInitialgifts.amount,
//       payment_method: "Stripe",
//       card_name: cardName,
//       style_code: "lorem",
//       pg_txn_id: "NULL",
//       // pg_api_response: JSON.stringify(errorStatus),
//       pg_status: "FAILED",
//       ...getInitialgifts,
//       ...postData,
//     };
//   }

//   if (errorStatus) {
//     OrderService.createOrder(postData)
//       .then((res) => {
//         if (res.success) {
//           let orders = {
//             order_id: res.order_id,
//             // cardlogo: getUser?.cardlogo || getUsergifts?.card_logo,
//           };

//           if (res.message === "Order placed successfully.") {
//             toast.success(res.message);
//           } else if (res.message === "Oops! something went wrong.") {
//             toast.error(res.message?.replace(/ /g, "_"));
//           }

//           // history.push("/ordersummary/" + orders.order_id, orders.cardlogo);
//           history.push("/ordersummary/" + orders.order_id);
//           // localStorage.setItem("cardlogo", orders.cardlogo);
//           localStorage.removeItem("byforyourData");
//           localStorage.removeItem("initialData");
//           localStorage.removeItem("gitafriendData");
//           localStorage.removeItem("initialDatagift");
//           localStorage.removeItem("card_name");
//         } else {
//           toast.error("payement failed");
//         }
//       })
//       .catch((error) => {
//         console.log(error, "error");
//       });
//   }
// };
