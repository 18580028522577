import { DataService } from "./DataService";

export const AuthService = {
  Register: async (data) => {
    return DataService.post("/registration-post", data);
  },
  login: async (data) => {
    return DataService.post("/login-post", data);
  },
  googleLogin: async (data) => {
    return DataService.post("/google-login", data);
  },
  RegisterOTP: async (data) => {
    return DataService.post("/otp-verification", data);
  },
  ForgotPassword: async (data) => {
    return DataService.post("/forgot-password", data);
  },
  forgotpasswordOTP: async (data) => {
    return DataService.post("/verify-forgot-password-otp", data);
  },
  ResetNewpassword: async (data) => {
    return DataService.post("/reset-forgot-password", data);
  },
  EditProfile: async (data) => {
    return DataService.post("/auth/update-user", data);
  },
  getProfile: async (data) => {
    return DataService.get("/auth/user", data);
  },
};
export default AuthService;
