import React from "react";
import { Route, Link, Redirect } from "react-router-dom";
import Header from "../container/Header";

export function PrivateRoutes({
  children,
  roles,
  middlewares,
  fallback,
  ...rest
}) {
  return (
    <Route
      {...rest}
      render={() =>
        rest.value != null ? (
          <>
            <div className="content-wrapper">
              {/* <Header /> */}
              {children}
            </div>
          </>
        ) : (
          <Redirect
            to={{
              pathname: "/",
            }}
            from="*"
          />
        )
      }
    />
  );
}
