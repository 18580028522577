import React, { useEffect, useState, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CommonHeader from "./commonHeader";
import { Storage } from "../Storage/Storage";
import { useHistory } from "react-router-dom";
import { Spinner } from "react-bootstrap";

import { toast } from "react-toastify";
import SimpleReactValidator from "simple-react-validator";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import OrderService from "../service/OrderService";
import PaymentGatewayService from "../service/PaymentGateway";
import PaymentForm from "../../src/container/paymentForm";

function By_for_yourself(onsideChnage) {
  const history = useHistory();
  const state = history.location.state;

  localStorage.setItem("card_name", state.name);
  const prifill = Storage.get("token");
  const [loading, setLoading] = useState(false);

  const [styleData, setStyleData] = useState([]);
  const [maxAmt, setMaxAmt] = useState("");
  const [minAmt, setMinAmt] = useState("");

  const [giftData, setGiftData] = useState({
    card_code: state && state.code,
    card_theme_code: state && state.themes[0].code,
    card_category_name: "Festival",
    card_description: state && state.description,
    card_terms: state && state.terms,
    card_how_to_use: state && state.how_to_use,
    currency: state && state.denominations[0].currency,
    recipient_type: "Buy for yourself",
    recipient_name: prifill && prifill.user.first_name,
    recipient_email: prifill && prifill.user.email,
    recipient_message: "NA",
    id: prifill && prifill.user.id,
    card_logo: state && state.card_logo,
  });

  const [, forceUpdate] = useState("");
  const [hide, sethide] = useState(true);
  const validator = useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } })
  );

  const [clientSecret, setClientSecret] = useState(false);

  const [initialValues, setInitialValues] = useState({
    amount: "",
    code: "",
  });

  const stripePromise = loadStripe(
    `${process.env.REACT_APP_STRIPE_PUBLISHING_API_KEY}`,
  );


  useEffect(() => {
    getStyle();
  }, []);

  const getStyle = () => {
    setLoading(true);
    OrderService.styleList()
      .then((res) => {
        setStyleData(res.data.results);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  const payNow = (e) => {
    e.preventDefault();
    let amt = (initialValues.amount * 100).toString();

    let postData = {
      amount: initialValues.amount * 100,
      // amount: amt,
      currency: "USD",
    };

    setLoading(true);
    localStorage.setItem("byforyourData", JSON.stringify(giftData));
    localStorage.setItem("initialData", JSON.stringify(postData));
    const formValid = validator.current.allValid();

    if (formValid) {
      // if (postData.amount >= state.denominations[0].amount) {
      if (prifill !== false) {
        PaymentGatewayService.paymentIntent(postData)
          .then((res) => {
            if (res.success) {
              setClientSecret(res.clientSecret);
              sethide(false);
              setTimeout(() => {
                setLoading(false);
              }, 1000);
            } else {
              setLoading(false);
            }
          })
          .catch((error) => {
            console.log(error, "error");
            setLoading(false);
          });
      } else {
        toast.error("Please login/register first to make an order.");
        setLoading(false);
      }

    } else {
      setLoading(false);
      validator.current.showMessages();
    }
  };

  var settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    autoplaySpeed: 3000,
    cssEase: "linear",
    autoplay: true,
  };

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };

  const selectHandler = (e, type) => {
    let value = e.amount / 100;
    state.denominations.filter((item) => {
      if (item.amount !== e.amount) {
        setClientSecret(false);
        sethide(true);
      }
    });
    setInitialValues({
      ...initialValues,
      amount: value ? value.toString() : "",
    });
  };

  const selectThemeHandler = (e) => {
    let value = e;
    styleData.filter((item) => {
      if (item.code !== e) {
        setClientSecret(false);
        sethide(true);
      }
    });
    setInitialValues({
      ...initialValues,
      code: value,
    });
  };

  const changeHandler = (e) => {
    const { value } = e.target;
    state.denominations.filter((item) => {
      if (item !== value) {
        setClientSecret(false);
        sethide(true);
      }
    });
    setInitialValues({
      ...initialValues,
      amount: value,
    });
  };


  const amtCheck = () => {
    if (
      initialValues.amount >
      state.denominations[state.denominations.length - 1].amount / 100
    ) {
      setMaxAmt("please enter less than or equal of maximum amount");
    } else {
      setMaxAmt("");
    }
    if (initialValues.amount < state.denominations[0].amount / 100) {
      setMinAmt("please enter greater than or equal of minimum amount");
    } else {
      setMinAmt("");
    }
  };

  return (
    <>
      <CommonHeader />
      {loading ? (
        <div className="fullloading text-center">
          <Spinner animation="grow" variant="light" />
        </div>
      ) : (
        ""
      )}
      <div className="tab-container">
        <div className="tab tab-active" data-id="tab1">
          <div className="container">
            <div className="row top-g-vchr">
              <div className="col-md-6">
                <Slider {...settings}>
                  <div>
                    <img
                      src={state && state.card_logo}
                      className="d-block w-100"
                      alt="..."
                    />
                  </div>
                  <div>
                    <img
                      src={state && state.card_logo}
                      className="d-block w-100"
                      alt="..."
                    />
                  </div>
                  <div>
                    <img
                      src={state && state.card_logo}
                      className="d-block w-100"
                      alt="..."
                    />
                  </div>
                </Slider>
              </div>
              <div className="col-md-6">
                <div className="Gift a Friend mt-3">
                  <div className="d-flex align-items-center">
                    <span className="frnd-gift">
                      <p
                        onClick={() => history.push(`/giftafriend`, state)}
                        className="mb-0"
                        style={{ cursor: "pointer" }}
                      >
                        Gift a Friend{" "}
                      </p>
                    </span>
                    <span className="buy-yrslf">
                      <p
                        className=" active  mb-0"
                        style={{ cursor: "pointer" }}
                      >
                        Buy for Yourself
                      </p>
                    </span>
                  </div>
                  <h3 className="sty-sfe">Stay Safe Card</h3>
                  <form className="form w-100">
                    <div className="dlr-cntnt">
                      <div className="amount-input">
                        {state &&
                          state.denominations.map((el, key) => {
                            return (
                              <a
                                name="amount"
                                onClick={() => {
                                  selectHandler(el, "amount");
                                }}
                                className={
                                  initialValues.amount == el.amount / 100
                                    ? "active"
                                    : ""
                                }
                                style={{
                                  marginBottom: "5px",
                                  cursor: "pointer",
                                }}
                              >
                                ${el.amount / 100}
                              </a>
                            );
                          })}
                        {validator.current.message(
                          "amount",
                          initialValues.amount,
                          "required",
                          { className: "text-danger" }
                        )}

                      </div>
                    </div>
                    <div className="clear"></div>

                    <div className="Select-Payment">
                      <span>
                        <p>Select Theme</p>

                        <div className="row px-2">
                          {styleData &&
                            styleData.map((el) => {
                              return (
                                <div className="col-md-3 col-3 px-2 mb-3">
                                  <div className="position-relative w-100">
                                    <input
                                      className="checked-b-green "
                                      id="db2086b5-b7ca-4b91-ac63-03a00e7dcc26"
                                      type="radio"
                                      name="style_code"
                                      onClick={(e) => {
                                        selectThemeHandler(el.code);
                                      }}
                                      value={initialValues.code}
                                    />
                                    <span className="checkbox">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="#25ae88"
                                        className="bi bi-check-circle-fill"
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                      </svg>{" "}
                                    </span>

                                    <label className="imgcode cursor-pointer border-selected-green w-100">
                                      <img
                                        style={{
                                          borderRadius: "10px",
                                          objectFit: "cover",
                                        }}
                                        htmlFor="db2086b5-b7ca-4b91-ac63-03a00e7dcc26"
                                        className={
                                          initialValues.style_code === el.code
                                            ? "border border-success w-100"
                                            : " w-100"
                                        }
                                        src={el.thumbnail_url}
                                        height="100px"
                                      />
                                    </label>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </span>
                    </div>

                    <div className="Select-Payment">
                      <div className="Select-Payment">
                        <div className="form-check chk"></div>
                      </div>
                      <span>
                        {clientSecret && (
                                
                          <Elements options={options} stripe={stripePromise}>
                          <p>SECURE CHECKOUT</p>
                            <PaymentForm />
                          </Elements>
                        )}
                      </span>
                    </div>
                    {hide ? (
                      <button
                        type="button"
                        className="btn btn-primary home-btn"
                        disabled={loading}
                        onClick={payNow}
                        style={{
                                  marginBottom: "90px",
                                }}
                      >
                        {!loading && "Confirm"}
                        {loading && (
                          <span
                            className="indicator-progress"
                            style={{ display: "block" }}
                          >
                            Please wait...{" "}
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                          </span>
                        )}
                      </button>
                    ) : (
                      ""
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default By_for_yourself;
