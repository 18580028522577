import React, { useState } from "react";
import { AuthService } from "../service/AuthService";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import { Spinner } from "react-bootstrap";
import { Storage } from "../Storage/Storage";

const passwordFormValidationSchema = Yup.object().shape({
  new_password: Yup.string()
    .min(8, "Minimum 8 character")
    .max(15, "Maximum 15 characters")
    .required("Password is required"),
  passwordConfirmation: Yup.string()
    .min(8, "Minimum 8 character")
    .max(15, "Maximum 15 characters")
    .required("Password is required")
    .oneOf([Yup.ref("new_password"), null], "Passwords must match"),
});

export default function ResetPassword({ showSidebar, hide, onsideChnage }) {
  const [loading, setLoading] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const [confrmType, setConfrmType] = useState("password");

  const initialValues = {
    new_password: "",
    passwordConfirmation: "",
  };
  const formik = useFormik({
    initialValues,

    validationSchema: passwordFormValidationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);

      let postData = {
        password: values.new_password,
        confirm_password: values.passwordConfirmation,
      };
      AuthService.ResetNewpassword(postData)
        .then((res) => {
          if (res.success) {
            hide();
            toast.success("Password Change Successfully");
            setLoading(false);
            formik.setValues(initialValues);
            Storage.remove("temp_token");
          } else {
            setLoading(false);
            toast.error(res.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          setSubmitting(false);
        });
    },
  });
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  const toggleConfrm = () => {
    if (confrmType === "password") {
      setConfrmType("text");
      return;
    }
    setConfrmType("password");
  };

  return (
    <>
      {loading ? (
        <div className="fullloading text-center">
          <Spinner animation="grow" variant="light" />
        </div>
      ) : (
        ""
      )}
      <div
        id="mySidenav"
        className={
          showSidebar ? "sidenav side-reset active" : "sidenav side-reset"
        }
      >
        <div className="right-card-rht">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h4 className="mb-0">Reset Password</h4>
            <a
              className="closebtn reset-navbar "
              onClick={() => {
                formik.setValues(initialValues);
                formik.resetForm();
                hide(false);
              }}
              style={{ cursor: "pointer" }}
            >
              &times;
            </a>
          </div>
          <form
            onSubmit={formik.handleSubmit}
            id="kt_signin_change_password"
            className="form"
            noValidate
          >
            <div className="form-group">
              <span style={{ position: "relative", display: "flex" }}>
                <input
                  type={passwordType}
                  className="form-control form-control-lg form-control-solid "
                  id="newpassword"
                  {...formik.getFieldProps("new_password")}
                  maxLength={15}
                />
                <span
                  style={{
                    position: "absolute",
                    marginLeft: "300px",
                    paddingTop: "10px",
                  }}
                >
                  {passwordType === "password" ? (
                    <i
                      className="fa fa-eye-slash"
                      aria-hidden="true"
                      onClick={togglePassword}
                      style={{ cursor: "pointer" }}
                    ></i>
                  ) : (
                    <i
                      className="fa fa-eye"
                      aria-hidden="true"
                      onClick={togglePassword}
                      style={{ cursor: "pointer" }}
                    ></i>
                  )}
                </span>
              </span>

              {formik.touched.new_password && formik.errors.new_password && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block text-danger">
                    {formik.errors.new_password}
                  </div>
                </div>
              )}
            </div>
            <div className="form-group">
              <span style={{ position: "relative", display: "flex" }}>
                <input
                  type={confrmType}
                  className="form-control form-control-lg form-control-solid "
                  id="confirmpassword"
                  {...formik.getFieldProps("passwordConfirmation")}
                  maxLength={15}
                />
                <span
                  style={{
                    position: "absolute",
                    marginLeft: "300px",
                    paddingTop: "10px",
                  }}
                >
                  {confrmType === "password" ? (
                    <i
                      className="fa fa-eye-slash"
                      aria-hidden="true"
                      onClick={toggleConfrm}
                      style={{ cursor: "pointer" }}
                    ></i>
                  ) : (
                    <i
                      className="fa fa-eye"
                      aria-hidden="true"
                      onClick={toggleConfrm}
                      style={{ cursor: "pointer" }}
                    ></i>
                  )}
                </span>
              </span>

              {formik.touched.passwordConfirmation &&
                formik.errors.passwordConfirmation && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block text-danger">
                      {formik.errors.passwordConfirmation}
                    </div>
                  </div>
                )}
            </div>
            <div className="form-group">
              <div className="d-flex justify-content-between align-items-center">
                <p className="account-left mb-0">
                  {" "}
                  Enter your E-mail ID &nbsp;
                  <span
                    style={{ color: "#0392ca", cursor: "pointer" }}
                    onClick={() => hide()}
                  >
                    Login
                  </span>
                </p>
                <button
                  type="submit"
                  className="email-navbar btn btn-theme"
                  style={{ cursor: "pointer" }}
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
