import React, { useState } from "react";
import { AuthService } from "../service/AuthService";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";

export default function ForgotPassword({
  showSidebar,
  hide,
  onsideChnage,
  onAuthChange,
  setRunTimer
}) {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");

  const formSubmit = (e) => {
    e.preventDefault();
    let postData = {
      email: email,
    };
    localStorage.setItem("fEmail", JSON.stringify(postData));
    localStorage.setItem("temp", postData.email);
    setLoading(true);
    AuthService.ForgotPassword(postData)
      .then((result) => {
        console.log(result);
        if (result.success) {
          setRunTimer((t) => !t);
          onAuthChange();
          hide();
          toast.success(result.message);
          setEmail("");
          setLoading(false);
        } else {
          toast.error(result.message);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err, "err");
        setLoading(false);
      });
  };
  return (
    <>
      {loading ? (
        <div className="fullloading text-center">
          <Spinner animation="grow" variant="light" />
        </div>
      ) : (
        ""
      )}
      <div
        id="mySidenav"
        className={
          showSidebar ? "sidenav side-forget active" : "sidenav side-forget"
        }
      >
        <div className="right-card-rht">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h4 className="mb-0">Forgot Password</h4>
            <a
              className="closebtn forget-nav"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setEmail("");
                hide(false);
              }}
            >
              &times;
            </a>
          </div>
          <form action="">
            <div className="form-group">
              <input
                type="email"
                className="form-control"
                value={email}
                placeholder="Enter Email ID"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="form-group">
              <div className="d-flex justify-content-between align-items-center">
                <p className="account-left mb-0">
                  {" "}
                  Already have an account?
                  <br />
                  <span
                    style={{ color: "#0392ca", cursor: "pointer" }}
                    onClick={() => hide()}
                  >
                    Login
                  </span>
                </p>
                <button
                  type="submit"
                  className="email-navbar btn btn-theme"
                  onClick={(e) => {
                    formSubmit(e);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
