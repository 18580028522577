import React, { useEffect } from "react";
import OrderService from "../service/OrderService";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
const queryParams = new URLSearchParams(window.location.search);
const payment_intent = queryParams.get("payment_intent");
const payment_intent_client_secret = queryParams.get(
  "payment_intent_client_secret"
);
const redirect_status = queryParams.get("redirect_status");

function PaymentRedirect() {
  const history = useHistory();

  useEffect(() => {
    if (localStorage.getItem("token") && localStorage.getItem("paymentredirect")) createOrder();
  }, []);

  const getUser = JSON.parse(localStorage.getItem("byforyourData"));
  const getInitial = JSON.parse(localStorage.getItem("initialData"));
  const cardName = localStorage.getItem("card_name");
  const getUsergifts = JSON.parse(localStorage.getItem("gitafriendData"));
  const getInitialgifts = JSON.parse(localStorage.getItem("initialDatagift"));

  const createOrder = () => {
    let postData = {};
    if (getUser) {
      postData = {
        ...postData,
        ...getUser,
      };
    }
    if (getUsergifts) {
      postData = {
        ...postData,
        ...getUsergifts,
      };
    }
    if (getInitial) {
      postData = {
        amount: getInitial.amount,
        payment_method: "Stripe",
        card_name: cardName,
        style_code: "lorem",
        pg_txn_id: payment_intent,
        pg_api_response: payment_intent_client_secret,
        // pg_status: redirect_status,
        pg_status: "COMPLETED",

        // pg_status: "COMPLETED",
        ...getInitial,
        ...postData,
      };
    }
    if (getInitialgifts) {
      postData = {
        amount: getInitialgifts.amount,
        payment_method: "Stripe",
        card_name: cardName,
        style_code: "lorem",
        pg_txn_id: payment_intent,
        pg_api_response: payment_intent_client_secret,
        // pg_status: redirect_status,
        pg_status: "COMPLETED",
        ...getInitialgifts,
        ...postData,
      };
    }

    OrderService.createOrder(postData)
      .then((res) => {
        if (res.success) {
          let orders = {
            order_id: res.order_id,
            // cardlogo: getUser?.cardlogo || getUsergifts?.card_logo,
          };
          if (res.message === "Order placed successfully.") {
            toast.success(res.message);
          } else if (res.message === "Oops! something went wrong.") {
            toast.error(res.message);
          }
          history.push("/ordersummary/" + orders.order_id);
          // history.push("/ordersummary/" + orders.order_id, orders.cardlogo);
          // localStorage.setItem("cardlogo", orders.cardlogo);

          localStorage.removeItem("byforyourData");
          localStorage.removeItem("initialData");
          localStorage.removeItem("gitafriendData");
          localStorage.removeItem("initialDatagift");
          localStorage.removeItem("card_name");
        } else {
          toast.error(res.message);
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  return (
    <>
      <div className="load-6">
        <div className="letter-holder">
          <div className="l-1 letter">P</div>
          <div className="l-2 letter">a</div>
          <div className="l-3 letter">y</div>
          <div className="l-4 letter">m</div>
          <div className="l-5 letter">e</div>
          <div className="l-6 letter">n</div>
          <div className="l-7 letter">t</div>&nbsp;&nbsp;&nbsp;
          <div className="l-2 letter">S</div>
          <div className="l-2 letter">u</div>
          <div className="l-2 letter">c</div>
          <div className="l-2 letter">c</div>
          <div className="l-2 letter">e</div>
          <div className="l-2 letter">s</div>
          <div className="l-2 letter">s</div>
          <div className="l-2 letter">f</div>
          <div className="l-2 letter">u</div>
          <div className="l-2 letter">l</div>
          <div className="l-2 letter">l</div>
          <div className="l-9 letter">.</div>
          <div className="l-10 letter">.</div>
          <div className="l-10 letter">.</div>
        </div>
      </div>
    </>
  );
}

export default PaymentRedirect;
