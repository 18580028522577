import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import vLeft from "../assets/media/v-left.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CommonHeader from "./commonHeader";
import { useHistory, useParams } from "react-router-dom";
import { Storage } from "../Storage/Storage";
import { Spinner } from "react-bootstrap";
import OrderService from "../service/OrderService";
import { toast } from "react-toastify";
import { jsPDF } from "jspdf";

export default function Order_Summary() {

  const history = useHistory();
  const params = useParams();
  const doc = new jsPDF();
  const myState = history.location.state;
  const logoCard = localStorage.getItem("cardlogo");
  const prifill = Storage.get("token");
  const [loading, setLoading] = useState(false);
  const [orderData, setOrderData] = useState([]);
  const [orderAmt, setOrderAmt] = useState();
  const [totalAmt, setTotalAmt] = useState();
  let mail = localStorage.getItem("temp");
  var settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    autoplaySpeed: 3000,
    cssEase: "linear",
    autoplay: true,
  };

  useEffect(() => {
    getOrderData();
  }, []);

  const formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth("aug") + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [month, day, year].join("/");
  };

  const getOrderData = () => {
    setLoading(true);
    let postData = {
      id: params.id,
    };
    OrderService.retrive_order(postData)
      .then((res) => {
        setOrderData(res.data);
        if (res.data && res.data.amount && res.data.amount.includes(".00")) {
          let newAmt = res.data.amount.replace(".00", "");
          setOrderAmt(newAmt);
          setTotalAmt(newAmt);
        } else {
          setOrderAmt(res.data.amount);
          setTotalAmt(res.data.amount);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error, "error");
      });
  };
  const resendBtn = () => {
    let postData = {
      id: params.id,
    };
    OrderService.downloadImg(postData)
      .then((res) => {
        toast.success(res.message);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };
  const downloadCard = () => {
    let dataurl = orderData && orderData.card_pdf;

    fetch(dataurl).then(function (t) {
      return t.blob().then((b) => {
        var filename = dataurl.substring(dataurl.lastIndexOf("/") + 1);
        var a = document.createElement("a");
        a.href = URL.createObjectURL(b);
        a.setAttribute("download", filename);
        a.click();
      });
    });
    window.open(orderData.card_pdf, "_blank");
    // window.open(REACT_APP_API_IMAGE + orderData.card_pdf, "_blank");
  };

  return (
    <>
      <CommonHeader />
      {loading ? (
        <div className="fullloading text-center">
          <Spinner animation="grow" variant="light" />
        </div>
      ) : (
        ""
      )}
      <iframe id="iframe" style={{ display: "none" }}></iframe>

      <div className="tab-container">
        <div className="tab tab-active" data-id="tab1">
          <div className="container">
            <div className="row v-gift">
              <div className="col-md-6">
                <h4 style={{ fontFamily: "poppins", marginLeft: "5px" }}>
                  Stay Safe
                </h4>
                <div className="gift-v">
                  <div>
                    {orderData && orderData.card_logo === null ? (
                      <img src={logoCard} className="d-block w-100" alt="..." />
                    ) : (
                      <img
                        src={orderData && orderData.card_logo}
                        className="d-block w-100"
                        alt="..."
                      />
                    )}
                  </div>

                  <p className="btm-txt">
                    {orderData?.card_description}
                  </p>
                  <div className="multi-btn">
                    {orderData.pg_status == "FAILED" && ""}

                    {orderData.pg_status == "COMPLETED" &&
                      orderData.status == "0" &&
                      ""}

                    {orderData.pg_status == "COMPLETED" &&
                      orderData.status == "1" && (
                        <div>
                          <a>
                            <button
                              type="button"
                              className="btn btn-info Download"
                              onClick={() => {
                                downloadCard();
                              }}
                            >
                              Download
                            </button>
                          </a>
                          {
                            (orderData?.sender_email == mail && orderData?.recipient_type === "GIFT") &&
                            <a>
                              <button
                                type="button"
                                className="btn btn-info Download"
                                onClick={() => {
                                  resendBtn();
                                }}
                              >
                                Re-send
                              </button>
                            </a>
                          }
                        </div>
                      )}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <h4 style={{ fontFamily: "poppins", marginLeft: "5px" }}>
                  Order Summary
                </h4>
                <div className="order-summry mb-4">

                  <table className="table">
                    <tbody>
                      <tr>
                        <td width="180">Gift Card Name</td>
                        <td> {orderData && orderData.card_name}</td>
                      </tr>
                      <tr>
                        <td width="180">Price</td>
                        <td>${orderAmt}</td>
                      </tr>
                      <tr>
                        <td width="180">Total</td>
                        <td><span className="dollr">${totalAmt}</span></td>
                      </tr>
                    </tbody>
                  </table>


                </div>
                {
                  (orderData?.recipient_type == "GIFT" &&
                    orderData?.recipient_email == mail) ? (
                    <h4 style={{ fontFamily: "poppins" }}>Sender Details</h4>
                  ) : (
                    <h4 style={{ fontFamily: "poppins" }}>Recipient Details</h4>
                  )}

                <div className="Recipient mt-3">

                  <table className="table">
                    <tbody>
                      <tr>
                        <td>Name</td>
                        {orderData &&
                          orderData.recipient_type == "GIFT" &&
                          orderData.recipient_email == mail ? (
                          <td> <span className="text-secondary">
                            {orderData && orderData.sender_first_name}
                          </span></td>

                        ) : (
                          <td>

                            <span className="text-secondary">
                              {orderData && orderData.recipient_name}
                            </span>
                          </td>
                        )}

                      </tr>
                      <tr>
                        <td> Email</td>

                        {orderData &&
                          orderData.recipient_type == "GIFT" &&
                          orderData.recipient_email == mail ? (

                          <td><span className="text-secondary">
                            {orderData && orderData.sender_email}
                          </span></td>

                        ) : (

                          <td>  <span className="text-secondary">
                            {orderData && orderData.recipient_email}
                          </span></td>

                        )}


                      </tr>
                      {
                        orderData && orderData.recipient_message == "NA" ? "" :
                          <tr>
                            <td>Message</td>
                            <td> <span className="text-secondary">
                              {orderData && orderData.recipient_message}
                            </span></td>
                          </tr>
                      }
                      <tr>
                        <td>Order ID</td>
                        <td> <span className="text-secondary">{orderData?.txn_id}</span></td>
                      </tr>
                      <tr>
                        <td>Payment Method</td>
                        <td>
                          <span className="text-secondary">
                            {" "}
                            {orderData && orderData.payment_method}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td> Status</td>
                        <td>
                          <span className="text-secondary">
                            {orderData.pg_status == "COMPLETED" &&
                              orderData.status == "1" ?
                              orderData.recipient_type == "GIFT" &&
                                orderData.recipient_email == mail
                                ? "Received" :
                                orderData.recipient_type == "GIFT" &&
                                  orderData.sender_email == mail
                                  ? "Delivered"
                                  : "Purchased"
                              : orderData.pg_status == "COMPLETED" &&
                                orderData.status == "0"
                                ? "Pending"
                                : orderData.pg_status == "PENDING" &&
                                  orderData.status == "0"
                                  ? "Pending"
                                  : orderData.pg_status == "REFUNDED"
                                    ? "Refunded"
                                    : "Failed"
                            }
                          </span>

                        </td>
                      </tr>
                      <tr>
                        <td>
                          Created At
                        </td>
                        <td>
                          <span className="text-secondary mt-3">
                            {formatDate(orderData && orderData.created_at)}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="btn-home">
                  <button
                    type="button"
                    className="btn btn-primary home-btn"
                    onClick={() => history.push("/")}
                  >
                    Back to Home
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="rootElementId" />
    </>
  );
}
