import React, { useState, useEffect } from "react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CommonHeader from "./commonHeader";
import OrderService from "../service/OrderService";
import AImg from "../assets/media/Profile-PNG-File.png";
import { Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { Storage } from "../Storage/Storage";
import { useHistory } from "react-router-dom";
import ReactPaginate from "react-paginate";

import Register from "../Auth/Register";
import Login from "../Auth/Login";
import VarifyemailOTP from "../Auth/Varify_email_OTP";
import ForgotPassword from "../Auth/ForgotPassword";
import AuthModel from "../Auth/AuthModel";
import ResetPassword from "../Auth/ResetPassword";
import Profile from "../Auth/Profile";
import EditProfile from "../Auth/EditProfile";
import AuthService from "../service/AuthService";
import leftimg from "../assets/media/left-img.png";

const REACT_APP_API_IMAGE = process.env.REACT_APP_API_IMAGE;

export default function Header() {
  const [sideBar, setSideBar] = useState(false);
  const [LoginsideBar, setLoginSideBar] = useState(false);
  const [profile, setProfile] = useState(false);
  const [Editprofile, setEditProfile] = useState(false);
  const [EmailOTP, setEmailOTPSideBar] = useState(false);
  const [Forgotpassword, setForgotPassword] = useState(false);
  const [ForgotAuth, setForgotAuth] = useState(false);
  const [Resetpassword, setResetPassword] = useState(false);
  const [profileInfo, setProfileInfo] = useState();
  const [userProfile, setUserProfile] = useState();
  const [registerData, setRegisterData] = useState();
  const [search, setSearch] = useState();

  const [loading, setLoading] = useState(false);

  const [productData, setProductData] = useState([]);
  const [show, setShow] = useState(false);
  const [showTerm, setShowTerm] = useState(false);
  const [howToUseData, setHowToUseData] = useState([]);
  const [termData, setTermData] = useState([]);

  const [imageData, setImageData] = useState([]);

  const [countDown, setCountDown] = useState(0);
  const [runTimer, setRunTimer] = useState(false);

  const [filteredData, setFilteredData] = useState(productData);

  const [styleData, setStyleData] = useState([]);

  const handleClose = () => setShow(false);
  const prifill = Storage.get("token");
  const history = useHistory();
  const handleCloseTerm = () => setShowTerm(false);

  useEffect(() => {
    getBanner();
  }, []);
  useEffect(() => {
    getHomeData();
  }, [search]);

  const getHomeData = () => {
    setLoading(true);

    OrderService.getProductList()
      .then((res) => {
        setProductData(res.data.results);
        setFilteredData(res.data.results);
        localStorage.setItem("getProduct", JSON.stringify(res.data.results));

        setLoading(false);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  var settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 1100,
    autoplaySpeed: 5000,
    cssEase: "linear",
  };

  const howToUse = (val) => {
    setShow(true);
    setHowToUseData(val.how_to_use);
  };

  const termOfUse = (val) => {
    setShowTerm(true);
    setTermData(val.terms);
  };
  const handlePageClick = (e) => {
    getHomeData(e.selected + 1);
  };
  const getBanner = () => {
    setLoading(true);
    OrderService.getBanner()
      .then((res) => {
        setImageData(res.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  useEffect(() => {
    if (localStorage.getItem("token")) getProfileInfo();

  }, []);

  const getProfileInfo = () => {
    AuthService.getProfile()
      .then((res) => {
        setProfileInfo(res.data);
        setUserProfile(res.data?.profile_pic);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  const showRegisterModal = () => {
    setSideBar(true);
    setLoginSideBar(false);
  };
  const showLoginModal = () => {
    setLoginSideBar(true);
    setSideBar(false);
  };
  const showProfile = () => {
    setProfile(true);
    setSideBar(false);
  };
  const showEditProfile = () => {
    setEditProfile(true);
    setSideBar(false);
    setProfile(false);
  };
  const ShowForgotPassword = () => {
    setSideBar(false);
    setForgotPassword(true);
  };
  const showForgotAuth = () => {
    setSideBar(false);
    setForgotAuth(true);
  };
  const showResetPassword = () => {
    setResetPassword(true);
    setSideBar(false);
  };
  const ShowEmailOTPModeal = () => {
    setEmailOTPSideBar(true);
    setSideBar(false);
  };

  const handleSearch = (e) => {
    let result = [];
    const regex = new RegExp(e.target.value, "i");

    result =
      productData &&
      productData.filter((data) => {
        let amountIndex = searchFromArray(data.denominations, e.target.value);
        return (
          data.name.search(regex) != -1 ||
          // data.terms.search(regex) != -1 ||
          // data.how_to_use.search(regex) != -1 ||
          // data.description.search(regex) != -1 ||
          amountIndex != -1
        );
      });

    setFilteredData(result);
  };

  const searchFromArray = (arr, search = "") => {
    let temp = search.replace(/\$/g, "");
    const regex = new RegExp(search, "i");

    return arr.findIndex((item) => {
      let amount = item.amount / 100;
      let text = amount.toString();
      return temp === text;
    });
  };

  useEffect(() => {
    getStyle();
  }, []);

  const getStyle = () => {
    OrderService.styleList()
      .then((res) => {
        setStyleData(res.data.results);
        // res.data.results.map((elem) => {
        // setStyleData({ colorcode: elem.code });
        // });
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  /// for OTP count

  useEffect(() => {
    let timerId;

    if (runTimer) {
      setCountDown(60 * 3);
      timerId = setInterval(() => {
        setCountDown((countDown) => countDown - 1);
      }, 1000);
    } else {
      clearInterval(timerId);
    }

    return () => clearInterval(timerId);
  }, [runTimer]);

  React.useEffect(() => {
    if (countDown < 0 && runTimer) {
      setRunTimer(false);
      setCountDown(0);
    }
  }, [countDown, runTimer]);

  const seconds = String(countDown % 60).padStart(2, 0);
  const minutes = String(Math.floor(countDown / 60)).padStart(2, 0);

  return (
    <>
      {loading ? (
        <div className="fullloading text-center">
          <Spinner animation="grow" variant="light" />
        </div>
      ) : (
        ""
      )}

      <div className="top-hdr">
        <nav className="navbar navbar-expand-lg navbar-light bg-light white">
          <div className="container-fluid">
            <a
              className="navbar-brand"
              onClick={() => history.push("/")}
              style={{ cursor: "pointer" }}
            >
              <img src={leftimg} />
              <p className="Serving">-Serving 24/7-</p>
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav m-auto mb-2 mb-lg-0 itms">
                <div className="d-flex justify-content-center">
                  <i className="fas fa-search srch"></i>
                  <input
                    className="form-control me-2"
                    type="search"
                    placeholder="What are you planning to gift today?"
                    aria-label="Search"
                    onChange={(event) => handleSearch(event)}
                  />
                </div>
              </ul>
              {prifill === false ? (
                <div className="d-flex align-items-center">
                  <span
                    onClick={() => showRegisterModal()}
                    className="signup-nav btn btn-transparent me-3"
                  >
                    Sign Up
                  </span>

                  <span
                    onClick={() => showLoginModal()}
                    className="signin-nav btn btn-theme"
                  >
                    Login
                  </span>
                </div>
              ) : (
                <div className="d-flex align-items-center">
                  <span
                    className="btn btn-secondary ordr-btn"
                    onClick={() => history.push("/orderhistory")}
                  >
                    Order History
                  </span>
                  <span
                    onClick={() => showProfile()}
                    className="profile-pic profile-navbar me-3"
                    style={{ cursor: "pointer" }}
                  >
                    {userProfile && userProfile != "NULL" ? (
                      <img src={REACT_APP_API_IMAGE + userProfile} />
                    ) : (
                      <img src="/fe/assets/images/Profile-PNG-File.png" />
                    )}
                  </span>
                  <span
                    style={{ cursor: "pointer" }}
                    className="john-txt"
                    onClick={() => showProfile()}
                  >
                    {profileInfo && profileInfo.first_name}
                  </span>
                </div>
              )}
            </div>
          </div>
        </nav>
      </div>

      <div className="bg-crsl bg-white">
        <div className="container-fluid">
          <Slider {...settings}>
            {imageData &&
              imageData.map((elem) => {
                return (
                  <img
                    src={REACT_APP_API_IMAGE + elem.image}
                    alt="..."
                  // height="243"
                  />
                );
              })}
          </Slider>
        </div>
      </div>

      <div className="container-fluid">
        <h3 className="populr ms-2">Most Popular</h3>
      </div>
      <div className="container-fluid Bg-clr">
        <div className="row">
          {filteredData &&
            filteredData.map((elem, key) => {
              return (
                <div className="col-md-3 width-rht">
                  <div className="crd-cntnt">
                    <Link
                      to={{
                        pathname: `/buyforyourself`,
                        state: elem,
                      }}
                    >
                      <div className="img-crd">
                        <img src={elem.card_logo} />
                      </div>
                    </Link>
                    <div className="end-to-end">
                      <span
                        className="unlimited"
                        onClick={() => history.push("/buyforyourself", elem)}
                        style={{ cursor: "pointer" }}
                      >
                        {elem.name}
                      </span>
                      <p
                        className="brnds"
                        onClick={() => history.push("/buyforyourself", elem)}
                        style={{ cursor: "pointer" }}
                      >
                        {elem.description.slice(0, 30) +
                          (elem.description.length > 30 ? "..." : "")}
                      </p>
                      <span
                        className="dlr"
                        onClick={() => history.push("/buyforyourself", elem)}
                        style={{ cursor: "pointer" }}
                      >
                        ${elem.denominations[0].amount / 100} - $
                        {elem.denominations[elem.denominations.length - 1]
                          .amount / 100}
                      </span>
                      <span className="card-dir">
                        <p className="use">
                          <a
                            onClick={() => howToUse(elem, key)}
                            style={{
                              cursor: "pointer",
                              textDecoration: "underline",
                            }}
                          >
                            How To Use
                          </a>
                        </p>
                        <p className="terms">
                          <a
                            onClick={() => termOfUse(elem, key)}
                            style={{
                              cursor: "pointer",
                              textDecoration: "underline",
                            }}
                          >
                            Terms Of Use
                          </a>
                        </p>
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>How to use</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {howToUseData === ""
            ? "How to use description is not available for this card."
            : howToUseData}
        </Modal.Body>
      </Modal>
      <Modal show={showTerm} onHide={handleCloseTerm}>
        <Modal.Header closeButton>
          <Modal.Title>Term Of Use</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {termData === ""
            ? "Terms of use is not available for this card."
            : termData}
        </Modal.Body>
      </Modal>

      <Register
        showSidebar={sideBar}
        onsideChnage={showLoginModal}
        onsideChnageEmail={() => ShowEmailOTPModeal()}
        hide={setSideBar}
        setRegisterData={setRegisterData}
        setRunTimer={setRunTimer}
      />

      <Login
        showSidebar={LoginsideBar}
        onsideChnage={showRegisterModal}
        onForgotChange={() => ShowForgotPassword()}
        hide={setLoginSideBar}
      />
      <ForgotPassword
        showSidebar={Forgotpassword}
        onsideChnage={() => showLoginModal()}
        onAuthChange={() => showForgotAuth()}
        hide={setForgotPassword}
        setRunTimer={setRunTimer}
      />
      <Profile
        showSidebar={profile}
        onProfileChange={() => showProfile()}
        onEditProfileChange={() => showEditProfile()}
        hide={setProfile}
      />
      <EditProfile
        showSidebar={Editprofile}
        ProfileInfo={() => getProfileInfo()}
        // onProfileChange={() => showProfile()}
        hide={setEditProfile}

      // profileFun={getProfileInfo}
      />
      <VarifyemailOTP
        showSidebar={EmailOTP}
        hide={setEmailOTPSideBar}
        onLoginChange={showLoginModal}
        minute={minutes}
        second={seconds}
        countdown={runTimer}
        setCountDown={setRunTimer}
        registerData={registerData}
      />

      <AuthModel
        showSidebar={ForgotAuth}
        onResetPassword={() => showResetPassword()}
        hide={setForgotAuth}
        minute={minutes}
        second={seconds}
        countdown={runTimer}
        setCountDown={setRunTimer}
      />
      <ResetPassword showSidebar={Resetpassword} hide={setResetPassword} />

      {/* {pagination.limit ? (
        <ReactPaginate
          initialPage={pagination.offset - 1}
          pageCount={pagination.count / pagination.limit}
          pageRange={2}
          marginPagesDisplayed={2}
          onPageChange={handlePageClick}
          containerClassName={"container"}
          previousLinkClassName={"page"}
          breakClassName={"page"}
          nextLinkClassName={"page"}
          pageClassName={"page"}
          disabledClassNae={"disabled"}
          activeClassName={"active"}
        />
      ) : null} */}

      {/* {productData &&
        productData.length > 0 &&
        pagination.count > pagination.limit && (
          <ReactPaginate
            breakLabel="..."
            nextLabel="next >"
            onPageChange={(e) => handlePageClick(e)}
            forcePage={currentPage - 1}
            pageRangeDisplayed={5}
            pageCount={Math.ceil(pagination.count / pagination.limit)}
            previousLabel="< previous"
            renderOnZeroPageCount={null}
            marginPagesDisplayed={2}
            containerClassName={"pagination"}
            activeClassName={"active"}
          />
        )} */}
    </>
  );
}
