import React from "react";
import { Redirect } from "react-router-dom";

const ProtectedRoutes = (props) => {
    const Component = props.component;
    const isRedirect = localStorage.getItem("paymentredirect");
    console.log("isRedirect", isRedirect)

    if (!isRedirect) {
        return <Redirect to="/" from="*" />;
    } else {
        return (
            <div className="content-wrapper">
                <Component />
            </div>
        );
    }
};
export default ProtectedRoutes;
