import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Storage } from "../Storage/Storage";
import CommonHeader from "./commonHeader";
import { Spinner } from "react-bootstrap";
import OrderService from "../service/OrderService";
import ReactPaginate from "react-paginate";

export default function Order_History() {
  const history = useHistory();
  const params = useParams();
  const prifill = Storage.get("token");
  const [loading, setLoading] = useState(false);
  const [orderHistory, setOrderHistory] = useState([]);
  const [search, setSearch] = useState("");

  const [data, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [orderAmt, setOrderAmt] = useState();
  let mail = localStorage.getItem("temp");
  localStorage.removeItem("paymentredirect")


  useEffect(() => {
    getOrderData();
  }, [search, page]);

  const getOrderData = () => {
    setLoading(true);

    let param = {
      search: search,
      page: page,
    };

    OrderService.Order_History(param)
      .then((res) => {
        setTotalPages(res.data.data);
        if (page <= 1) {
          setOrderHistory([...res.data.data]);
        } else {
          setOrderHistory([...orderHistory, ...res.data.data]);
        }

        setLoading(false);
      })
      .catch((error) => {
        console.log(error, "error");
        setLoading(false);
      });
  };

  const searchHandler = (value) => {
    setSearch(value);
    setPage(1);
  };

  const formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth("aug") + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [month, day, year].join("/");
  };

  return (
    <>
      <CommonHeader />
      {loading ? (
        <div className="fullloading text-center">
          <Spinner animation="grow" variant="light" />
        </div>
      ) : (
        ""
      )}
      <div className="tab-container">
        <div className="tab tab-active" data-id="tab1">
          <div className="container sky-color">
            <div className="top-mrgn d-sm-flex">
              <h4 className="ordr-histry">Order History</h4>
              <div className="rht-srch search-order-his">
                <div className="d-flex rht-srch">
                  <i className="fas fa-search"></i>
                  <input
                    className="form-control me-2"
                    type="search"
                    placeholder="Search"
                    aria-label="Search"
                    onChange={(e) => searchHandler(e.target.value)}
                  />
                </div>
              </div>
            </div>

            {orderHistory &&
              orderHistory.map((elem, i) => {
                return (
                  <div className="order-crd" key={i}>
                    <ul>
                      <li>
                        <p className="first-txt">Order ID</p>
                        <p className="first-txt">Card Type</p>
                      </li>
                      <li>
                        <p className="sec-txt">{elem.txn_id}</p>
                        <p className="sec-txt">{elem.card_name}</p>
                      </li>
                      <li className="rht-brdr">
                        <p className="first-txt">Payment Method</p>
                        <p className="first-txt">Status</p>
                      </li>
                      <li>
                        <p className="sec-txt">{elem.payment_method}</p>
                        <p
                          className={
                            elem.pg_status == "COMPLETED" && elem.status == "0" ?
                              "text-warning fw-bold" :
                              elem.pg_status == "COMPLETED"
                                ? "text-success fw-bold"
                                :
                                elem.pg_status == "PENDING"
                                  ? "text-warning fw-bold"
                                  : elem.pg_status == "REFUNDED"
                                    ? "text-info fw-bold"
                                    : elem.pg_status == "FAILED"
                                      ? "text-danger fw-bold"
                                      : ""
                          }
                        // className={
                        //   elem.pg_status == "COMPLETED"
                        //     ? "grn-txt"
                        //     : elem.pg_status == "COMPLETED" &&
                        //       elem.status == "0"
                        //     ? "yel-txt"
                        //     : elem.pg_status == "FAILED"
                        //     ? "text-danger fw-bold"
                        //     : ""
                        // }
                        >
                          {elem.pg_status == "COMPLETED" && elem.status == "1"
                            ? elem.recipient_type == "GIFT" &&
                              elem.recipient_email == mail
                              ? "Received"
                              : "Purchased"
                            : elem.pg_status == "COMPLETED" &&
                              elem.status == "0"
                              ? "Pending"
                              : elem.pg_status == "COMPLETED" &&
                                elem.status == "1" ?
                                "Purchased"
                                : elem.pg_status == "PENDING" &&
                                  elem.status == "0"
                                  ? "Pending"
                                  : elem.pg_status == "REFUNDED"
                                    ? "Refunded"
                                    : "Failed"}
                          {/* {elem.pg_status === "COMPLETED" &&
                            elem.status === "1" &&
                            "Delivered"}
                          {elem.pg_status === "COMPLETED" &&
                            elem.status === "0" &&
                            "Pending"}

                          {elem.pg_status === "FAILED" && "Failed"}
                          {elem.pg_status === "REFUNDED" && "Refunded"} */}
                        </p>
                      </li>
                      <li className="rht-brdr">
                        <p className="first-txt">Amount</p>
                        <p className="first-txt">Currency</p>
                      </li>
                      <li>
                        {elem && elem.amount.includes(".00") ? (
                          <p className="sec-txt">
                            {" "}
                            {elem.amount.replace(".00", "")}
                          </p>
                        ) : (
                          <p className="sec-txt"> {elem.amount}</p>
                        )}
                        <p className="sec-txt">{elem.currency}</p>
                      </li>
                      <li className="rht-brdr">
                        <p className="first-txt">Created At</p>
                        {elem &&
                          elem.recipient_type == "GIFT" &&
                          elem.recipient_email == mail ? (
                          <p className="first-txt">Sender Email</p>
                        ) : (
                          <p className="first-txt">Recipient Email</p>
                        )}
                      </li>
                      <li>
                        <p className="sec-txt">{formatDate(elem.created_at)}</p>

                        {elem &&
                          elem.recipient_type == "GIFT" &&
                          elem.recipient_email == mail ? (
                          <p className="sec-txt">{elem.sender_email} </p>
                        ) : (
                          <p className="sec-txt">{elem.recipient_email}</p>
                        )}
                      </li>
                      <li>
                        <div className="view-align">
                          <button
                            type="button"
                            onClick={() =>
                              history.push("/ordersummary/" + elem.id)
                            }
                            className="btn btn-light view"
                          >
                            View
                          </button>
                        </div>
                      </li>
                    </ul>
                  </div>
                );
              })}
            <div className="clearfix"></div>

            {orderHistory.length <= 0 ? (
              <p style={{ textAlign: "center" }}>No orders found</p>
            ) : (
              data !== page && (
                <div className="col-md-12 text-center">
                  <button
                    className="btn btn-light view"
                    onClick={() => setPage(page + 1)}
                  >
                    {loading ? "Loading..." : "Load More"}
                  </button>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </>
  );
}
