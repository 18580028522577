import React, { useEffect, useState } from "react";
import OTPInput from "otp-input-react";
import { AuthService } from "../service/AuthService";
import { toast } from "react-toastify";
import { Storage } from "../Storage/Storage";
import { Spinner } from "react-bootstrap";

export default function AuthModel({
  showSidebar,
  hide,
  onResetPassword,
  minute,
  second,
  countdown,
  setCountDown,
}) {
  const [loading, setLoading] = useState(false);
  const getFMail = JSON.parse(localStorage.getItem("fEmail"));
  const [OTP, setOTP] = useState("");
  const [OTPTemp, setOTPTemp] = useState("");


  const formSubmit = () => {
    let postData = {
      email: getFMail,
      otp: OTP,
    };

    setLoading(true);
    AuthService.forgotpasswordOTP(postData)
      .then((result) => {
        if (result.success) {
          onResetPassword();
          Storage.set("temp_token", result.data);
          toast.success(result.message);
          setLoading(false);
          setOTP("");
          setOTPTemp("");
          hide(false);
        } else {
          toast.error(result.message);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const otpInput = (e) => {
    let lastotp = e.charAt(e.length - 1);

    if (lastotp !== "" && lastotp !== "*") {
      let otp = OTP.toString() + lastotp.toString();

      setOTP(otp);
    } else {
      let otp = OTP;
      otp = otp.slice(0, -1);

      setOTP(otp);
    }

    let str = "";
    for (let i = 0; i < e.length; i++) {
      str += "*";
    }

    setOTPTemp(str);
  };

  const resendOTP = () => {
    let mail = getFMail
    let postData = {
      email: mail.email,
    };
    setLoading(true);
    AuthService.ForgotPassword(postData)
      .then((result) => {
        if (result.success) {
          setCountDown((t) => !t);
          toast.success(result.message);
          setLoading(false);
        } else {
          toast.error(result.message);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err, "err");
        setLoading(false);
      });
  };

  return (
    <>
      {loading ? (
        <div className="fullloading text-center">
          <Spinner animation="grow" variant="light" />
        </div>
      ) : (
        ""
      )}
      <div
        id="mySidenav"
        className={
          showSidebar ? "sidenav side-email active" : "sidenav side-email"
        }
      >
        <div className="right-card-rht">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h4 className="mb-0">Enter OTP </h4>
            <a
              className="closebtn email-navbar"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setOTP("");
                setOTPTemp("");
                hide(false);
              }}
            >
              &times;
            </a>
          </div>
          <p style={{ color: "#AAAAAA" }}>
            {" "}
            Please enter verification code(OTP) sent to your email
          </p>

          <div>
            <OTPInput
              className="myOtp"
              value={OTPTemp}
              onChange={(e) => otpInput(e)}
              autoFocus
              OTPLength={4}
              otpType="number"
              disabled={false}
            />
          </div>

          <br />
          {
            (countdown) &&
            <span className="d-block mb-2"><small>Your OTP will be expired in <strong>{minute}: {second}</strong> minutes</small></span>
          }
          <div className="form-group">
            <div className="d-flex justify-content-between align-items-center">
              <p className="account-left mb-0">
                {" "}
                Already have an account? <br />
                <span
                  style={{ color: "#0392ca", cursor: "pointer" }}
                  onClick={() => hide()}
                >
                  Login
                </span>
              </p>
              {
                countdown ?
                  <button
                    type="button"
                    className="reset-navbar btn btn-theme"
                    onClick={() => {
                      formSubmit();
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    Submit
                  </button>
                  :
                  <button
                    type="button"
                    className="btn btn-theme"
                    onClick={() => {
                      resendOTP();
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    Resend OTP
                  </button>
              }

            </div>
          </div>

        </div>
      </div>
    </>
  );
}
