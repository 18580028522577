import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CommonHeader from "./commonHeader";
import { Storage } from "../Storage/Storage";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import OrderService from "../service/OrderService";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PaymentForm from "../../src/container/paymentForm";
import SimpleReactValidator from "simple-react-validator";
import PaymentGatewayService from "../service/PaymentGateway";

export default function Gift_a_friend() {
  const history = useHistory();
  const state = history.location.state;
  const prifill = Storage.get("token");
  const [loading, setLoading] = useState(false);
  const [amountError, setAmountError] = useState(false);
  const [styleData, setStyleData] = useState([]);
  const [hide, sethide] = useState(true);
  const [maxAmt, setMaxAmt] = useState("");
  const [minAmt, setMinAmt] = useState("");
  const [giftData, setGiftData] = useState({
    card_code: state.code,
    card_theme_code: state.themes[0].code,
    card_category_name: "Festival",
    card_description: state.description,
    card_terms: state.terms,
    card_how_to_use: state.how_to_use,
    currency: state.denominations[0].currency,
    recipient_type: "GIFT",
    card_logo: state.card_logo,
  });
  const [, forceUpdate] = useState("");
  const validator = useRef(
    new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate } })
  );
  const emailInputRef = React.useRef(null);

  const [clientSecret, setClientSecret] = useState(false);
  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLISHING_API_KEY
  );
  const [initialValues, setInitialValues] = useState({
    amount: "",
    recipient_name: "",
    recipient_email: "",
    recipient_message: "",
    payment_method: "",
    card_name: "",
    style_code: "",
    confirm_email: "",
  });

  useEffect(() => {
    getStyle();
  }, []);

  const getStyle = () => {
    setLoading(true);
    OrderService.styleList()
      .then((res) => {
        setStyleData(res.data.results);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  const payNow = (e) => {
    e.preventDefault();

    const formValid = validator.current.allValid();
    let postData = {};
    let amt = (initialValues.amount * 100).toString();

    console.log(validator.current, "formValid");
    setLoading(true);
    if (!validator.current.fieldValid("amount")) {
      setAmountError(true);
    } else {
      setAmountError(false);
    }
    if (formValid) {
      postData = {
        amount: initialValues.amount * 100,
        currency: "USD",
        recipient_name: initialValues.recipient_name,
        recipient_email: initialValues.recipient_email,
        recipient_message: initialValues.recipient_message,
        payment_method: "Stripe",
        card_name: initialValues.card_code,

        style_code: initialValues.code,
        ...giftData,
      };

      localStorage.setItem("gitafriendData", JSON.stringify(giftData));
      localStorage.setItem("initialDatagift", JSON.stringify(postData));
      // if (postData.amount >= state.denominations[0].amount) {
      // if (
      //   postData.amount <=
      //   state.denominations[state.denominations.length - 1].amount
      // )
      if (postData.recipient_email === initialValues.confirm_email) {
        if (prifill !== false) {
          PaymentGatewayService.paymentIntent(postData)
            .then((res) => {
              if (res.success) {
                setClientSecret(res.clientSecret);
                sethide(false);
                setTimeout(() => {
                  setLoading(false);
                }, 1000);
              } else {
                setLoading(false);
              }
            })
            .catch((error) => {
              console.log(error, "error");
              setLoading(false);
            });
        } else {
          toast.error("Please login/register first to make an order..");
          setLoading(false);
        }
      } else {
        toast.error("Email must match to recipient email");
        setLoading(false);
      }
      // else {
      //   toast.error("Please enter maximum amount or less");
      //   setLoading(false);
      // }
      // } else {
      //   toast.error("plz enter minimum amount or greater");
      //   setLoading(false);
      // }
    } else {
      validator.current.showMessages();
      setLoading(false);
      // validator.current.autoForceUpdate.forceUpdate();
    }
  };

  var settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    autoplaySpeed: 3000,
    cssEase: "linear",
    autoplay: true,
  };
  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };
  const selectHandler = (e, type) => {
    let value = e.amount / 100;
    state.denominations.filter((item) => {
      if (item.amount !== e.amount) {
        setClientSecret(false);
        sethide(true);
      }
    });
    setInitialValues({
      ...initialValues,
      amount: value ? value.toString() : "",
    });
    // if (
    //   value >
    //   state.denominations[state.denominations.length - 1].amount / 100
    // ) {
    //   setMaxAmt("please enter less than or equal of maximum amount");
    // } else {
    //   setMaxAmt("");
    // }
    // if (value < state.denominations[0].amount / 100) {
    //   setMinAmt("please enter greater than or equal of minimum amount");
    // } else {
    //   setMinAmt("");
    // }
  };

  const selectThemeHandler = (e) => {
    let value = e;
    styleData.filter((item) => {
      if (item.code !== e) {
        setClientSecret(false);
        sethide(true);
      }
    });
    setInitialValues({
      ...initialValues,
      code: value,
    });
  };

  const changeHandler = (e) => {
    const { value, name } = e.target;
    state.denominations.filter((item) => {
      if (item !== value) {
        setClientSecret(false);
        sethide(true);
      }
    });
    setInitialValues({ ...initialValues, [name]: value });
  };

  // const amtValid = () => {
  //   const formValid = validator.current.fieldValid("amount");
  //   if (!formValid) {
  //     validator.current.showMessageFor("amount");
  //   }
  // };
  const inputValid = () => {
    const formValid = validator.current.fieldValid("recipient_name");
    if (!formValid) {
      validator.current.showMessageFor("recipient_name");
    }
  };
  const emailValid = () => {
    const formValid = validator.current.fieldValid("recipient_email");

    if (!formValid) {
      validator.current.showMessageFor("recipient_email");
    }
  };
  const confrmEmailValid = () => {
    const formValid = validator.current.fieldValid("confirm_email");
    if (!formValid) {
      validator.current.showMessageFor("confirm_email");
    }
  };

  const messageValid = () => {
    const formValid = validator.current.fieldValid("recipient_message");
    if (!formValid) {
      validator.current.showMessageFor("recipient_message");
    } else {
      validator.current.showMessageFor("");
    }
  };
  const amtValid = () => {
    // const formValid = validator.current.fieldValid("amount");
    // if (!formValid) {
    //   validator.current.showMessageFor("amount");
    // }
  };

  // const amtValid = () => {
  //   if (
  //     initialValues.amount >
  //     state.denominations[state.denominations.length - 1].amount / 100
  //   ) {
  //     setMaxAmt("please enter less than or equal of maximum amount");
  //   } else {
  //     setMaxAmt("");
  //   }
  //   if (initialValues.amount < state.denominations[0].amount / 100) {
  //     setMinAmt("please enter greater than or equal of minimum amount");
  //   } else {
  //     setMinAmt("");
  //   }
  //   validator.current.showMessageFor("amount");
  // };

  // const amtCheck = () => {
  //   if (
  //     initialValues.amount >
  //     state.denominations[state.denominations.length - 1].amount / 100
  //   ) {
  //     setMaxAmt("please enter less than or equal of maximum amount");
  //   } else {
  //     setMaxAmt("");
  //   }
  //   if (initialValues.amount < state.denominations[0].amount / 100) {
  //     setMinAmt("please enter greater than or equal of minimum amount");
  //   } else {
  //     setMinAmt("");
  //   }
  // };

  return (
    <>
      <CommonHeader />
      {loading ? (
        <div className="fullloading text-center">
          <Spinner animation="grow" variant="light" />
        </div>
      ) : (
        ""
      )}
      <div className="tab-container">
        <div className="tab tab-active" data-id="tab1">
          <div className="container">
            <div className="row top-g-vchr">
              <div className="col-md-6">
                <Slider {...settings}>
                  <div>
                    <img
                      src={state.card_logo}
                      className="d-block w-100"
                      alt="..."
                    />
                  </div>
                  <div>
                    <img
                      src={state.card_logo}
                      className="d-block w-100"
                      alt="..."
                    />
                  </div>
                  <div>
                    <img
                      src={state.card_logo}
                      className="d-block w-100"
                      alt="..."
                    />
                  </div>
                </Slider>
              </div>
              <div className="col-md-6">
                <div className="Gift a Friend mt-3">
                  <div className="d-flex align-items-center">
                    <span className="frnd-gift ">
                      <p className="active mb-0" style={{ cursor: "pointer" }}>
                        Gift a Friend
                      </p>
                    </span>
                    <span
                      className="buy-yrslf"
                      onClick={() => history.push(`/buyforyourself`, state)}
                      style={{ cursor: "pointer" }}
                    >
                      <p className="  mb-0">Buy for Yourself</p>
                    </span>
                  </div>
                  <h3 className="sty-sfe">Stay Safe Card</h3>
                  <form className="form w-100" onSubmit={payNow}>
                    <div className="dlr-cntnt">
                      <div className="amount-input">
                        {state.denominations.map((el) => {
                          return (
                            <a
                              name="amount"
                              onClick={() => {
                                selectHandler(el, "amount");
                              }}
                              className={
                                initialValues.amount == el.amount / 100
                                  ? "active"
                                  : ""
                              }
                              // checked={true}
                              style={{ marginBottom: "5px", cursor: "pointer" }}
                            >
                              ${el.amount / 100}
                            </a>
                          );
                        })}
                        {/* {amountError ? (
                          <span className="text-danger">
                            Amount field is required
                          </span>
                        ) : null} */}
                        {validator.current.message(
                          "amount",
                          initialValues.amount,
                          "required",
                          { className: "text-danger" }
                        )}
                        {/* <span className="d-inline-flex">
                          <span
                            style={{
                              fontSize: "20px",
                              fontFamily: "poppins",
                              margin: "0px 4px",
                            }}
                          >
                            or
                          </span>
                          <span className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text">$</span>
                            </div>
                            <input
                              type="number"
                              id="amount"
                              placeholder="Enter Amount"
                              className="form-control"
                              name="amount"
                              onChange={(e) => {
                                changeHandler(e);
                              }}
                              value={initialValues.amount}
                              // onBlur={() =>
                              //   validator.current.showMessageFor("amount")
                              // }
                              onBlur={() => amtValid()}
                              onKeyUp={() => amtCheck()}
                            />

                            {validator.current.message(
                              "amount",
                              initialValues.amount,
                              "required|max:6",
                              { className: "text-danger" }
                            )}
                          </span>
                        </span>
                        <label className="text-danger">{maxAmt}</label>
                        <label className="text-danger">{minAmt}</label> */}
                      </div>
                    </div>
                    <div className="clear"></div>
                    <span className="d-inline-block">
                      <input
                        type="text"
                        className="all-input-all"
                        placeholder="Recipient Name"
                        name="recipient_name"
                        onChange={(e) => {
                          changeHandler(e);
                        }}
                        value={initialValues.recipient_name}
                        onBlur={() => {
                          inputValid();
                        }}
                      />
                      {validator.current.message(
                        "recipient_name",
                        initialValues.recipient_name,
                        "required|min:1|max:20|alpha_space",
                        { className: "text-danger" }
                      )}
                    </span>

                    <span className="d-inline-block">
                      <input
                        type="email"
                        className="all-input-all"
                        placeholder="Recipient Email"
                        name="recipient_email"
                        onChange={(e) => {
                          changeHandler(e);
                        }}
                        value={initialValues.recipient_email}
                        onBlur={() => {
                          emailValid();
                        }}
                      />

                      {validator.current.message(
                        "recipient_email",
                        initialValues.recipient_email,
                        "required|email|min:1|max:40",
                        { className: "text-danger" }
                      )}
                    </span>

                    <span className="d-inline-block">
                      {" "}
                      <input
                        type="email"
                        className="all-input-all"
                        placeholder="Confirm Recipient Email"
                        name="confirm_email"
                        onChange={(e) => {
                          changeHandler(e);
                        }}
                        value={initialValues.confirm_email}
                        onBlur={() => {
                          confrmEmailValid();
                        }}
                        disabled={
                          initialValues.recipient_email === "" ? true : false
                        }
                        style={{ backgroundColor: "white" }}
                      />
                      {validator.current.message(
                        "confirm_email",
                        initialValues.confirm_email,
                        "required|email|min:1|max:40",
                        { className: "text-danger" }
                      )}
                    </span>

                    <textarea
                      rows="4"
                      placeholder="message"
                      cols="50"
                      name="recipient_message"
                      form="usrform"
                      className="all-input-all"
                      onChange={(e) => {
                        changeHandler(e);
                      }}
                      value={initialValues.recipient_message}
                      onBlur={() => {
                        messageValid();
                      }}
                    ></textarea>
                    {validator.current.message(
                      "recipient_message",
                      initialValues.recipient_message,
                      "required|min:1|max:50",
                      { className: "text-danger" }
                    )}
                    <div className="Select-Payment">
                      <span>
                        <p>Select Theme</p>

                        <div className="row px-2">
                          {styleData &&
                            styleData.map((el) => {
                              return (
                                <div className="col-md-3 col-2 px-2 mb-3">
                                  <div className="position-relative w-100">
                                    <input
                                      className=" checked-b-green "
                                      id="db2086b5-b7ca-4b91-ac63-03a00e7dcc26"
                                      type="radio"
                                      name="style_code"
                                      onClick={(e) => {
                                        selectThemeHandler(el.code);
                                      }}
                                      value={initialValues.code}
                                    />
                                    <span className="checkbox">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="#25ae88"
                                        className="bi bi-check-circle-fill"
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                      </svg>{" "}
                                    </span>

                                    <label className="imgcode cursor-pointer border-selected-green w-100">
                                      <img
                                        style={{
                                          borderRadius: "10px",
                                          objectFit: "cover",
                                        }}
                                        htmlFor="db2086b5-b7ca-4b91-ac63-03a00e7dcc26"
                                        className={
                                          initialValues.style_code === el.code
                                            ? "border border-success w-100"
                                            : " w-100"
                                        }
                                        src={el.thumbnail_url}
                                        height="100px"
                                      />
                                    </label>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </span>
                    </div>
                    <div className="Select-Payment">
                      <div className="Select-Payment">
                        <div className="form-check chk">
                        </div>
                      </div>
                      <span>
                        {clientSecret && (
                          <Elements options={options} stripe={stripePromise}>
                          <p>SECURE CHECKOUT</p>
                            <PaymentForm />
                          </Elements>
                        )}
                      </span>
                    </div>
                    {hide ? (
                      <button
                        type="submit"
                        className="btn btn-primary home-btn"
                        disabled={loading}
                        style={{
                                  marginBottom: "90px",
                                }}
                      >
                        {!loading && "Confirm"}
                        {loading && (
                          <span
                            className="indicator-progress"
                            style={{ display: "block" }}
                          >
                            Please wait...{" "}
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                          </span>
                        )}
                      </button>
                    ) : (
                      ""
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
