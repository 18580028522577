import { DataService } from "./DataService";

export const OrderService = {
  HomeBanner: async (data) => {
    return DataService.get("/base-url", data);
  },
  getBanner: async (data) => {
    return DataService.get("/home-banner", data);
  },
  getProductList: async (data) => {
    // return DataService.get("/get-product-list", data);
    return DataService.get(`/get-product-list`, data);
  },
  createOrder: async (data) => {
    return DataService.post("/create-order", data);
  },
  retrive_order: async (data) => {
    return DataService.get(`/retrive-order/${data.id}`);
  },
  Order_History: async (data) => {
    return DataService.get(
      // `/order-history?page=1&per_page=10&search=365EG_2262332971c0513`,data
      `/order-history?page=${data.page}&per_page=10&search=${data.search}`
    );
  },
  styleList: async (data) => {
    return DataService.get(`/get-style-list`);
  },
  downloadImg: async (data) => {
    return DataService.get(`/resend-order-email/${data.id}`);
  },
};
export default OrderService;
