import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import clsx from "clsx";
import { AuthService } from "../service/AuthService";
import { toast } from "react-toastify";
import { Storage } from "../Storage/Storage";
import { Spinner } from "react-bootstrap";
import AImg from "../assets/media/Profile-PNG-File.png";
import EditImg from "../assets/media/edit.svg";

const REACT_APP_API_IMAGE = process.env.REACT_APP_API_IMAGE;

const profileDetailsSchema = Yup.object().shape({
  first_name: Yup.string()
    .required("First name is required")
    .min(1, "Minimum 1 character")
    .max(20, "Maximum 20 characters")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed"),
  last_name: Yup.string()
    .required("Last name is required")
    .min(1, "Minimum 1 character")
    .max(20, "Maximum 20 characters")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed"),
  email: Yup.string()
    .required("email is required")
    .min(1, "Minimum 1 character")
    .max(40, "Maximum 40 characters"),
  new_password: Yup.string()
    .min(8, "Minimum 8 character")
    .max(15, "Maximum 15 characters"),
});

const EditProfile = ({ showSidebar, hide, ProfileInfo }) => {
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState("");
  const [userProfile, setUserProfile] = useState();
  const [passwordType, setPasswordType] = useState("password");
  const initialValues = {
    first_name: "",
    last_name: "",
    email: "",
    new_password: "",
    profile_pic: image,
  };

  useEffect(() => {
    if (localStorage.getItem("token")) getProfileInfo();

  }, []);

  const getProfileInfo = () => {
    AuthService.getProfile()
      .then((res) => {
        setUserProfile(res.data?.profile_pic);
        setImage(res.data?.profile_pic);

        formik.setValues({
          first_name: res.data && res.data.first_name,
          last_name: res.data && res.data.last_name,
          email: res.data && res.data.email,
        });
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema: profileDetailsSchema,

    onSubmit: (values) => {
      values.image = image;
      const formData = new FormData();
      formData.append("first_name", values.first_name);
      formData.append("last_name", values.last_name);
      formData.append("email", values.email);
      if (values.new_password === "") {
        formData.append("new_password", "");
      } else {
        formData.append("new_password", values.new_password);
      }
      formData.append("profile_pic", values.image);
      setLoading(true);
      AuthService.EditProfile(formData)
        .then((res) => {
          if (res.success === true) {
            toast.success(res.message, { autoClose: 3000 });
            setTimeout(() => {
              window.location.reload()
            }, 2000);
            hide();
            ProfileInfo()
            setLoading(false);
          } else {
            toast.error(res.message);
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error, "error");
        });
    },
  });
  const imageHandler = function (e) {
    const fileList = e.target.files;
    if (!fileList) return;
    setImage(fileList[0]);
  };

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  function closeHandler() {
    getProfileInfo()
    hide(false)
  }

  return (
    <>
      {loading ? (
        <div className="fullloading text-center">
          <Spinner animation="grow" variant="light" />
        </div>
      ) : (
        ""
      )}
      <div
        id="mySidbar"
        className={
          showSidebar
            ? "sidenav profile-setting-reset active"
            : "sidenav profile-setting-reset "
        }
      >
        <div className="right-card-rht">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h4 className="mb-0">Edit Profile</h4>
            <a
              href="javascript:void(0)"
              className="closebtn profile-setting-navbar"
              onClick={() => closeHandler()}
            >
              &times;
            </a>
          </div>

          <form onSubmit={formik.handleSubmit}>
            <div className="d-flex align-items-center edit-profile">
              <div className="profile-set-pic">
                {/* <div className="profile-pic-set"> */}
                <div className="circle">
                  {image && typeof image === "object" ? (
                    <img
                      className="profile-pic-set"
                      src={URL.createObjectURL(image)}
                      alt="profile img"
                    />
                  ) : (
                    <img
                      src={image !== null ? "/fe/assets/images/Profile-PNG-File.png" : REACT_APP_API_IMAGE + image}
                      className="rounded-circle"
                      height={79}
                    />
                  )}
                </div>
                <label className="editButton">
                  <input
                    type="file"
                    id="myFile"
                    name="filename"
                    accept="image/*"
                    onChange={(e) => {
                      imageHandler(e);
                    }}
                  // style={{ cursor: "pointer" }}
                  />

                  <img
                    style={{ cursor: "pointer" }}
                    src={EditImg}
                    className="edit"
                    height="20"
                    width="20"
                  />
                </label>
              </div>
              {/* </div> */}
            </div>

            <div className="form-group mt-3">
              <label>Name</label>
              <input
                {...formik.getFieldProps("first_name")}
                type="text"
                className="form-control"
                placeholder="First name"
                value={formik.values.first_name}
                name="first_name"
              />
              {formik.touched.first_name && formik.errors.first_name && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block text-danger">
                    {formik.errors.first_name}
                  </div>
                </div>
              )}
            </div>
            <div className="form-group">
              <label>Last Name</label>
              <input
                {...formik.getFieldProps("last_name")}
                placeholder="Last name"
                type="text"
                autoComplete="off"
                value={formik.values.last_name}
                name="last_name"
                className={clsx("form-control mt-2")}
              />
              {formik.touched.last_name && formik.errors.last_name && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block text-danger">
                    <span role="alert">{formik.errors.last_name}</span>
                  </div>
                </div>
              )}
            </div>

            <div className="form-group">
              <label>Email</label>
              <input
                {...formik.getFieldProps("email")}
                placeholder="Email"
                type="email"
                autoComplete="off"
                value={formik.values.email}
                name="email"
                className={clsx("form-control mt-2")}
                disabled
              />
              {formik.touched.email && formik.errors.email && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block text-danger">
                    <span role="alert">{formik.errors.email}</span>
                  </div>
                </div>
              )}
            </div>

            <div className="form-group">
              <label>Change Password</label>
              <span style={{ position: "relative", display: "flex" }}>
                <input
                  placeholder="password"
                  {...formik.getFieldProps("new_password")}
                  type={passwordType}
                  autoComplete="off"
                  value={formik.values.new_password}
                  className={clsx("form-control mt-2")}
                  maxLength={15}
                />
                <span
                  style={{
                    position: "absolute",
                    marginLeft: "300px",
                    paddingTop: "17px",
                  }}
                >
                  {passwordType === "password" ? (
                    <i
                      className="fa fa-eye-slash"
                      aria-hidden="true"
                      onClick={togglePassword}
                      style={{ cursor: "pointer" }}
                    ></i>
                  ) : (
                    <i
                      className="fa fa-eye"
                      aria-hidden="true"
                      onClick={togglePassword}
                      style={{ cursor: "pointer" }}
                    ></i>
                  )}
                </span>
              </span>
              {formik.touched.new_password && formik.errors.new_password && (
                <div className="fv-plugins-message-container text-danger">
                  <div className="fv-help-block">
                    <span role="alert">{formik.errors.new_password}</span>
                  </div>
                </div>
              )}
            </div>
            <button
              type="submit"
              className="btn btn-theme"
              style={{ cursor: "pointer" }}
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </>
  );
};
export default EditProfile;
