import React, { useState } from "react";
import OTPInput from "otp-input-react";
import { AuthService } from "../service/AuthService";
import { toast } from "react-toastify";
import { Storage } from "../Storage/Storage";
import { Spinner } from "react-bootstrap";

export default function VarifyemailOTP({
  showSidebar,
  hide,
  minute,
  second,
  countdown,
  setCountDown,
  registerData
}) {
  const [loading, setLoading] = useState(false);
  const [email, setLeadEmail] = useState("");
  const [OTP, setOTP] = useState("");
  const [OTPTemp, setOTPTemp] = useState("");
  const getMail = localStorage.getItem("temp");


  const formSubmit = () => {
    let postData = {
      email: getMail,
      otp: OTP,
    };

    setLoading(true);
    AuthService.RegisterOTP(postData)
      .then((result) => {
        console.log(result);
        if (result.success) {
          hide();
          window.location.reload();
          Storage.set("token", result.data);
          toast.success(result.message);

          setLoading(false);
        } else {
          toast.error(result.message);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const otpInput = (e) => {
    let lastotp = e.charAt(e.length - 1);

    if (lastotp !== "" && lastotp !== "*") {
      let otp = OTP.toString() + lastotp.toString();

      setOTP(otp);
    } else {
      let otp = OTP;
      otp = otp.slice(0, -1);

      setOTP(otp);
    }

    let str = "";
    for (let i = 0; i < e.length; i++) {
      str += "*";
    }

    setOTPTemp(str);
  };

  const resendOTP = () => {
    const formData = new FormData();
    formData.append("first_name", registerData?.first_name);
    formData.append("last_name", registerData?.last_name);
    formData.append("email", registerData?.email);
    formData.append("password", registerData?.password);
    AuthService.Register(formData)
      .then((res) => {
        if (res.success) {
          setCountDown((t) => !t);

          localStorage.setItem("temp", registerData?.email);
          toast.success(res.message);

        } else {
          toast.error(res.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <>
      {loading ? (
        <div className="fullloading text-center">
          <Spinner animation="grow" variant="light" />
        </div>
      ) : (
        ""
      )}

      <div
        id="mySidenav"
        className={
          showSidebar
            ? "sidenav sidenav-signin active"
            : "sidenav sidenav-signin"
        }
      >
        <div className="right-card-rht">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h4 className="mb-0">Verify Email And OTP</h4>
            <a style={{ cursor: "pointer" }} className="closebtn signin-nav" onClick={() => hide(false)}>
              &times;
            </a>
          </div>
          <div>
            <OTPInput
              value={OTPTemp}
              onChange={(e) => otpInput(e)}
              autoFocus
              OTPLength={4}
              otpType="number"
              disabled={false}
            />
          </div>
          <br />
          {
            (countdown) &&
            <span className="d-block mb-2"><small>Your OTP will be expired in <strong>{minute}: {second}</strong> minutes</small></span>
          }
          <div className="form-group">
            <div className="d-flex justify-content-between align-items-center">
              {
                countdown ?
                  <button
                    type="submit"
                    className="btn btn-theme"
                    onClick={() => {
                      formSubmit();
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    Verify
                  </button>
                  :
                  <button
                    type="submit"
                    className="btn btn-theme"
                    onClick={() => {
                      resendOTP();
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    Resend OTP
                  </button>
              }

            </div>
          </div>
        </div>
      </div>
    </>
  );
}
