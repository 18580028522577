import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import { Spinner, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { AuthService } from "../service/AuthService";
import { Storage } from "../Storage/Storage";
import { GoogleLogin } from '@react-oauth/google';
import { useGoogleOneTapLogin, useGoogleLogin } from '@react-oauth/google';

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Wrong email format")
    .min(1, "Minimum 1 character")
    .max(40, "Maximum 40 characters")
    .required("Email is required"),
  password: Yup.string()
    .min(8, "Minimum 8 character")
    .max(15, "Maximum 15 characters")
    .required("Password is required"),
});

export default function Login({
  showSidebar,
  hide,
  onsideChnage,
  onForgotChange,
}) {
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(true);
  const [showTerm, setShowTerm] = useState(false);
  const [showPrv, setShowPrv] = useState(false);
  const [passwordType, setPasswordType] = useState("password");

  const initialValues = {
    email: "",
    password: "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);

      let postData = {
        email: values.email,
        password: values.password,
      };
      // localStorage.setItem("pwd", postData.password);
      AuthService.login(postData)
        .then((res) => {
          if (res.success) {
            hide();
            Storage.set("token", res.data);
            window.location.reload();
            toast.success(res.message);
            localStorage.setItem("temp", postData.email);
            setLoading(false);
          } else {
            setLoading(false);
            toast.error(res.message);
            setStatus("The login detail is incorrect");
          }
        })
        .catch((error) => {
          setLoading(false);
          setSubmitting(false);
          setStatus("The login detail is incorrect");
        });
    },
  });

  const handleChange = (e) => {
    setShow(!show);
  };

  const showPolicy = () => {
    setShowPrv(true);
  };
  const showTerms = () => {
    setShowTerm(true);
  };

  const handleClose = () => setShowPrv(false);
  const handleCloseTerm = () => setShowTerm(false);

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  const login = useGoogleLogin({
    onSuccess: credentialResponse => console.log(credentialResponse),
  });
 
  
  const googleLogin = (data) => {
    setLoading(true);
    AuthService.googleLogin(data)
        .then((res) => {
          if (res.success) {
            Storage.set("token", res.data);
            window.location.reload();
            toast.success(res.message);
            localStorage.setItem("temp", data.email);
            setLoading(false);
          } else {
            setLoading(false);
            toast.error(res.message);
          }
        })
        .catch((error) => {
          setLoading(false);
        });
  }
  return (
    <>
      {loading ? (
        <div className="fullloading text-center">
          <Spinner animation="grow" variant="light" />
        </div>
      ) : (
        ""
      )}
      <div
        id="mySidenav"
        className={
          showSidebar
            ? "sidenav sidenav-signin active"
            : "sidenav sidenav-signin"
        }
      >
        <div className="right-card-rht">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h4 className="mb-0">Login to 365eGifts</h4>
            <a
              className="closebtn signin-nav"
              style={{ cursor: "pointer" }}
              onClick={() => {
                formik.setValues(initialValues);
                formik.resetForm();
                hide(false);
              }}
            >
              &times;
            </a>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className="form-group">
              <input
                placeholder="Email"
                {...formik.getFieldProps("email")}
                className={clsx("form-control")}
                type="email"
                name="email"
                autoComplete="off"
                value={formik.values.email}
              />
              {formik.touched.email && formik.errors.email && (
                <div className="fv-plugins-message-container text-danger">
                  <span role="alert">{formik.errors.email}</span>
                </div>
              )}
            </div>
            <div className="form-group">
              <span style={{ position: "relative", display: "flex" }}>
                <input
                  placeholder="password"
                  {...formik.getFieldProps("password")}
                  // type="password"
                  type={passwordType}
                  autoComplete="off"
                  value={formik.values.password}
                  className={clsx("form-control")}
                  maxLength={15}
                />
                <span
                  style={{
                    position: "absolute",
                    marginLeft: "300px",
                    paddingTop: "8px",
                  }}
                >
                  {passwordType === "password" ? (
                    <i
                      className="fa fa-eye-slash"
                      aria-hidden="true"
                      onClick={togglePassword}
                      style={{ cursor: "pointer" }}
                    ></i>
                  ) : (
                    <i
                      className="fa fa-eye"
                      aria-hidden="true"
                      onClick={togglePassword}
                      style={{ cursor: "pointer" }}
                    ></i>
                  )}
                </span>
              </span>
              {formik.touched.password && formik.errors.password && (
                <div className="fv-plugins-message-container text-danger">
                  <div className="fv-help-block">
                    <span role="alert">{formik.errors.password}</span>
                  </div>
                </div>
              )}
            </div>
            <div className="form-group">
              <div className="form-check">
                <label className="form-check-label" htmlFor="flexCheckChecked">
                  Remember me
                </label>
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="flexCheckChecked"
                  style={{ cursor: "pointer" }}
                />
              </div>
            </div>
            <div className="form-group">
              <span
                style={{ color: "#0392ca", cursor: "pointer" }}
                onClick={() => onForgotChange()}
              >
                Forgot Password?
              </span>
              <div className="d-flex justify-content-between align-items-center">
              <p className="account-left mb-0" style={{ fontSize: "13px"}}>
                  {" "}
                  Don't have an account?{" "}
                  <span
                    style={{ color: "#0392ca", cursor: "pointer" }}
                    onClick={() => onsideChnage()}
                  >
                    Sign Up
                  </span>
                </p>
                <button
                  type="submit"
                  className="btn btn-theme"
                  style={{ cursor: "pointer" }}
                >
                  Login
                </button>
              </div>
              <div className="text-center">
                <span className="text-muted text-small" style={{
                  paddingTop: "10px"}}>-OR-</span>
              </div>
              <div className="text-center d-flex" style={{
                  paddingLeft: "75px",
                  paddingTop: "10px",
                  paddingRight: "80px"}} >
                <GoogleLogin
                    onSuccess={tokenResponse => {
                      googleLogin(tokenResponse);
                    }}
                    onError={() => {
                      console.log('Login Failed');
                    }}
                />
                    {/* <button onClick={() => login()}>
                      Sign in with Google 🚀{' '}
                    </button> */}
              </div>
            </div>
          </form>
        </div>
      </div>
      <Modal show={showPrv} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Privacy Policy</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ height: "600px", overflowX: "scroll" }}>
          <h5>
            <strong>Privacy Policy</strong>
          </h5>
          <p>
            This page informs you of our policies regarding the manner in which 
            we collect, use and disclose personal information we receive from
            users of our site (www.365eGifts.com). We use your personal
            information to better understand your usage of the site, and to
            collect traffic statistics.By using the site, you agree to the
            collection and use of information in accordance with this policy.
          </p>
          <h5>
            <strong>Personal Identification Information</strong>
          </h5>
          <p>
            <span>
              Like other site operators, we may collect personal identification
              information from Users in a variety of ways, when Users visit our
              site, register on the site, place an order, fill out a form, services, features or resources
              we make available on our Site. Users may be asked for, an
              appropriate , Name ,Email address, Credit card
              information.&nbsp;
            </span>
          </p>
          <h5>
            <strong>Non-personal identification information&nbsp;</strong>
          </h5>
          <p>
            <span style={{ fontWeight: "400" }}>
              We may collect non-personal identification information about Users
              whenever they interact with our Site. Non-personal identification
              information may include the browser name, the type of computer and
              technical information about Users means of connection to our Site,
              such as the operating system and the Internet service providers
              utilized and other similar information.&nbsp; Information we
              collect about you automatically;&nbsp;
            </span>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>&nbsp;</span>
          </p>
          <ol>
            <li>
              <strong> Location Information&nbsp;</strong>
              <span style={{ fontWeight: "400" }}>
                &ndash; Information that is automatically collected via
                analytics systems providers to determine your location,
                including your IP address and/or domain name and any external
                page that referred you to us, your login information, browser
                type and version, time zone setting, browser plug-in types and
                versions, operating system, and platform;&nbsp;
              </span>
            </li>
            <li>
              <strong> Log Information&nbsp;</strong>
              <span style={{ fontWeight: "400" }}>
                &ndash; Information that is generated by use of
                www.365eGifts.com Services that is automatically collected and
                stored in our server logs. This may include, but is not limited
                to, device-specific information, location information, system
                activity and any internal and external information related to
                pages that you visit, including the full Uniform Resource
                Locators (URL) clickstream to, through and from our Website or
                App (including date and time; page response times, download
                errors, length of visits to certain pages, page interaction
                information (such as scrolling, clicks, and mouse-overs), and
                methods used to browse away from the page;&nbsp;
              </span>
            </li>
          </ol>
          <p>
            <span style={{ fontWeight: "400" }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
            <strong>3 . Account Information&nbsp;</strong>
            <span style={{ fontWeight: "400" }}>
              &ndash; Information that is generated by your www.365eGifts.com
              account activity including, but not limited to, instructions
              regarding ordersx;&nbsp;
            </span>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
            <strong>4 . Correspondence&nbsp;</strong>
            <span style={{ fontWeight: "400" }}>
              &ndash; Information that you provide to us in written or oral
              correspondence, including opening a www.365eGifts.com, and with
              respect to ongoing customer support; and logging of phone numbers
              used to contact us.&nbsp;
            </span>
          </p>
          <p>
            <strong>How do we protect your personal information?&nbsp;</strong>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              We are serious about guarding the privacy of your Personal
              Information and work hard to protect Pay ward and you from
              unauthorized access to, or unauthorized alteration, disclosure, or
              destruction of Personal Information we collect and store. Measures
              we take include encryption with SSL; the availability of
              two-factor authentication at your discretion, and periodic review
              of our Personal Information collection, storage, and processing
              practices. We restrict access to your Personal Information only to
              those Pay ward employees, affiliates, and subcontractors who have
              a legitimate business need for accessing such information. We
              continuously educate and train our employees about the importance
              of confidentiality and privacy of customer information. We
              maintain physical, electronic, and procedural safeguards that
              comply with the relevant laws and regulations to protect your
              Personal Information from unauthorized access.&nbsp;
              Unfortunately, despite best practices and technical safeguards,
              the transmission of information via the internet is not completely
              secure. Although we do our best to protect your Personal Data, we
              cannot guarantee the security of your Personal Data during
              transmission, and any acts of transmission are at your own
              risk.&nbsp;
            </span>
          </p>
          <p>
      {/* <span style={{ fontWeight: "400" }}>Protection of Minors</span> */}
        </p>
         {/*} <p>
            <strong>www.365eGifts.com&nbsp;</strong> 
            <span style={{ fontWeight: "400" }}>
               Services We do not knowingly collect personal information from
              Minors. If you are a parent or guardian of a Minor, and you become
              aware that a Minor has provided personal information to
              www.365eGifts.com, please contact us at www.365eGifts.com and you
              may request to exercise your applicable access, rectification, 
              cancellation, and/or objection rights.&nbsp;
            </span> 
          </p> */}
          <p> 
            <span style={{ fontWeight: "400" }}>User choices:&nbsp;</span>
          </p>
          <p>
            <strong>Email Communications</strong>
            <span style={{ fontWeight: "400" }}>
              &nbsp;- Subject to applicable laws and regulations, we may from
              time to time send communications promoting services, products,
              facilities, or activities to you using information collected from
              you. We will never provide your Personal Information to third parties 
              for direct marketing or other unrelated purposes without your written
              consent. Unfortunately, we are unable to allow you to opt-out of
              the emails we send you for certain legal or administrative
              purposes.&nbsp;
            </span>
          </p>
          <p>
            <strong>Cookies&nbsp;</strong>
            <span style={{ fontWeight: "400" }}>
              - You may decline session cookies if your browser or browser
              add-on permits but choosing to remove or disable our cookies may
              interfere with your use and functionality of the website.&nbsp;
            </span>
          </p>
          <p>
            <strong>Right of Rectification&nbsp;</strong>
            <span style={{ fontWeight: "400" }}>
              - You have the right to correct any personal information We hold
              on you that is inaccurate, incorrect, or out of date.&nbsp;
            </span>
          </p>
          <p>
            <strong>Right of Erasure&nbsp;</strong>
            <span style={{ fontWeight: "400" }}>
              - You have the right to ask us to delete your data when it is no
              longer necessary, or no longer subject to a legal obligation to
              which www.365eGifts.com is subject to.&nbsp;
            </span>
          </p>
          <p>
            <strong>Portability</strong>
            <span style={{ fontWeight: "400" }}>
              &nbsp;- You may have the right to transfer certain of your
              personal data between data controllers, for example, to move your
              account details from one online platform to another.&nbsp;
            </span>
          </p>
         {/* <p>
            <strong>Location Information&nbsp;</strong>
            <span style={{ fontWeight: "400" }}>
              - You may be able to stop the collection of location information
              through your device settings or by following the standard
              uninstall process to remove our applications from your device;
              however, because such data is used by us to meet legal
              requirements, as well as for ongoing fraud and risk monitoring
              purposes, choosing to remove or disable location services may
              interfere with your use and functionality of the Kraken Exchange
              Services.&nbsp;
            </span>
         </p>*/}
          <p>
            <strong>Electronic Fund Transfers (&ldquo;EFTs&rdquo;)</strong>
            <span style={{ fontWeight: "400" }}>
              &nbsp;and Account Balances. Keyutech (www.365eGifts.com) has
              partnered with financial services software company Sila Inc. to
              offer you EFTs. When you create a
            </span>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              Keyutech Account, you might also be prompted to sign up for a Sila
              Account. You
            </span>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              authorize Keyutech (www.365eGifts.com) to share your identity and
              banking information with Sila Inc. to open and support your
              Keyutech (www.365eGifts.com) &nbsp;Account. It is your
              responsibility to make sure the data you provide us is accurate
              and complete. You must comply with Sila Inc.&rsquo;s privacy
              policy (the &ldquo;Sila Privacy Policy&rdquo;) when creating or
              using your Keyutech (
            </span>
            <a href="https://365egifts.com/">
              <span style={{ fontWeight: "400" }}>www.365eGifts.com</span>
            </a>
            <span style={{ fontWeight: "400" }}>
              ) Account. The Sila Privacy Policy may be modified from time to
              time, and the governing version is incorporated by reference into
              this Privacy Policy. Any term not defined in this [paragraph,
              section, or addendum] but defined in the Sila Privacy Policy
              assumes the meaning as defined in the Sila Privacy Policy.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>&nbsp;</span>
          </p>
          <p>
            <strong>California Privacy Rights</strong>
            <span style={{ fontWeight: "400" }}>
              . If you are a resident of the State of California, under the
              California&nbsp;Consumer Privacy Act (CCPA), you have the right to
              request information on how to exercise&nbsp;your disclosure choice
              options from companies conducting business in California.
              Specifically:
            </span>
          </p>
          <ul>
            <li style={{ fontWeight: "400" }} aria-level="1">
              <strong>Exercising the Right to Know</strong>
              <span style={{ fontWeight: "400" }}>
                . You may request, up to twice in a 12-month period,
                the&nbsp;following information about the personal information we
                have collected about you duringthe past 12 months:
              </span>
            </li>
            <li style={{ fontWeight: "400" }} aria-level="1">
              <span style={{ fontWeight: "400" }}>
                &nbsp;The categories and specific pieces of personal information
                we have collected about&nbsp;you.
              </span>
            </li>
            <li style={{ fontWeight: "400" }} aria-level="1">
              <span style={{ fontWeight: "400" }}>
                &nbsp;The categories of sources from which we collected the
                personal information.
              </span>
            </li>
            <li style={{ fontWeight: "400" }} aria-level="1">
              <span style={{ fontWeight: "400" }}>
                &nbsp;The business or commercial purpose for which we collected
                the personal&nbsp;information.
              </span>
            </li>
            <li style={{ fontWeight: "400" }} aria-level="1">
              <span style={{ fontWeight: "400" }}>
                &nbsp;The categories of third parties with whom we shared the
                personal information and
              </span>
            </li>
            <li style={{ fontWeight: "400" }} aria-level="1">
              <span style={{ fontWeight: "400" }}>
                &nbsp;The categories of personal information about you that we
                disclosed for a business&nbsp;purpose, and the categories of
                third parties to whom we disclosed that information&nbsp;for a
                business purpose.
              </span>
            </li>
          </ul>
          <ul>
            <li>
              <strong>Exercising the right to delete.</strong>
              <span style={{ fontWeight: "400" }}>
                You may request that we delete the personal information&nbsp;we
                have collected from you, subject to certain limitations under
                applicable law.
              </span>
            </li>
            <li>
              <strong>Exercising the right to opt-out from a sale</strong>
              <span style={{ fontWeight: "400" }}>
                . You may request to opt out of any &ldquo;sale&rdquo;
                of&nbsp;your personal information that may take place.
              </span>
            </li>
            <li>
              <strong>Non-discrimination</strong>
              <span style={{ fontWeight: "400" }}>
                . The CCPA provides that you may not be discriminated against
                for&nbsp;exercising these rights.
              </span>
            </li>
          </ul>
          <p>
            <span style={{ fontWeight: "400" }}>
              To submit a request to exercise any of the rights described above,
              you may&nbsp;&nbsp;contact [Business name] either via email to
              [your legal team email address here]&nbsp;&nbsp;or contact us via
              postal mail, proper postage prepaid, at:
            </span>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              Keyutech (www.365eGifts.com), Attn: Your California Privacy Rights
            </span>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              440 N Wolfe Rd Suite 152 Sunnyvale, CA, 94087
            </span>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>OR</span>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              emailing customer service at&nbsp;
            </span>
            <a href="mailto:support@365egifts.com">
              <span style={{ fontWeight: "400" }}>support@365eGifts.com</span>
            </a>
            <span style={{ fontWeight: "400" }}>.</span>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              All requests sent via postal mail must be labeled &ldquo;Your
              California Privacy Rights&rdquo;&nbsp;on the envelope or postcard
              and clearly stated on the actual request. For all&nbsp;requests,
              please include your name, street address (if you would like a
              response&nbsp;via postal mail), city, state, and zip code. We may
              need to verify your identity&nbsp;before responding to your
              request, such as verifying that the email address or&nbsp;contact
              information from which you send the request matches your email
              address&nbsp;or contact information that we have on file.
              Authentication based on a&nbsp;government-issued and valid
              identification document may be required. We will&nbsp;not accept
              requests via telephone or fax. We are not responsible for notices
              that&nbsp;are not labeled or sent properly, or do not have
              complete information.
            </span>
          </p>
          <p>
            <strong>Changes to this Privacy Policy</strong>
            <span style={{ fontWeight: "400" }}>&nbsp;</span>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              We may update this privacy policy from time to time. Any changes
              we may make to our Privacy Notice will be posted on this page and,
              where appropriate, notified to you by email. Please check back
              frequently to see any updates or changes to our Privacy Notice
            </span>
          </p>
          <p>&nbsp;</p>
        </Modal.Body>
      </Modal>
      <Modal show={showTerm} onHide={handleCloseTerm}>
        <Modal.Header closeButton>
          <Modal.Title>Terms of service</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ height: "600px", overflowX: "scroll" }}>
          <p>
            <strong>
              PLEASE READ THE FOLLOWING TERMS AND CONDITIONS CAREFULLY
            </strong>
          </p>
          <p>
            <strong>Definitions</strong>
          </p>
          <p>
            <strong>Customer Account Agreement:</strong>
            <span style={{ fontWeight: "400" }}>
              &nbsp;the 365egifts Investments, Inc.'s Customer Account
              Agreement.
            </span>
          </p>
          <p>
            <strong>Gift Card:</strong>
            <span style={{ fontWeight: "400" }}>&nbsp;A 365egift card.</span>
          </p>
          <p>
            <strong>Gift Cards Account:</strong>
            <span style={{ fontWeight: "400" }}>
              &nbsp;A 365egift Cards account.
            </span>
          </p>
         {/* <p>
            <strong>Investments Account:</strong>
            <span style={{ fontWeight: "400" }}>
              &nbsp;An investments account with 365egifts Investments, Inc.,
              member&nbsp;(not a member of FINRA)
            </span>
              </p> */}
          <p>
            <strong>Linked Sites:</strong>
            <span style={{ fontWeight: "400" }}>
              &nbsp;Third parties&rsquo; websites and content.
            </span>
          </p>
          <p>
            <strong>Service:</strong>
            <span style={{ fontWeight: "400" }}>&nbsp;the&nbsp;</span>
            <a href="https://365egifts.com/">
              <span style={{ fontWeight: "400" }}>www.365eGifts.com</span>
            </a>
            <span style={{ fontWeight: "400" }}>
              &nbsp;website and all its associated websites and services.
            </span>
          </p>
          <p>
            <strong>365egifts: </strong>
            <span style={{ fontWeight: "400" }}>
              365egifts, Inc. and/or its divisions, affiliates and subsidiaries.
            </span>
          </p>
          <p>
            <strong>Terms and Conditions:</strong>
            <span style={{ fontWeight: "400" }}>
              &nbsp;These terms and conditions of use and all modifications
              thereto.
            </span>
          </p>
          <p>
            <strong>Use of the Service</strong>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              The Service is provided by 365egifts for your personal,
              non-commercial use, subject to the terms of the Terms and
              Conditions, and the additional terms and disclosures that may be
              published from time to time by 365egifts. By using this site, you
              are deemed to have agreed to the Terms and Conditions.
            </span>
          </p>
          <p>
            <strong>Changes to the Terms and Conditions</strong>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              365egifts may change the Terms and Conditions from time to time
              and at any time, and without actual notice to you. All such
              changes to these Terms and Conditions will appear on the
              365egifts.com website. By using this site after we post any
              changes, you agree to accept those changes, whether or not you
              have reviewed them. If at any time you choose not to accept these
              Terms and Conditions of use please do not use this site.
            </span>
          </p>
          <p>
            <strong>Changes to the Service</strong>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              365egifts reserves the right, without prior notice, to make
              changes to the Service, to terminate access to the Service, or to
              take other actions it reasonably believes necessary to comply with
              the law or to protect its rights and your rights. We reserve the
              right to monitor any and all use of the Service. Any access or
              attempt to access or use the Service for any unauthorized or
              illegal purpose is strictly prohibited.
            </span>
          </p>
          <p>
            <strong>Electronic Delivery</strong>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              The electronic copy of any document related to your relationship
              with 365egifts is considered to be the authentic, complete, and
              enforceable record of such document. Such electronic document is
              admissible in a judicial, administrative or arbitration proceeding
              just as if the document were in printed form.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              By using the Service, you agree to have communications related to
              your relationship with 365egifts transmitted to you
              electronically. This consent will remain in effect until you
              revoke your consent in writing. It may take up to three (3) days
              to process a revocation of consent.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              Your consent also applies to all applicable tax-reporting forms
              supplied to you, including any applicable federal tax reporting
              forms, such as 1099-DIVs (for dividends) and 1099-Bs (for proceeds
              from the sale of shares), for the current tax year and all future
              tax years. You may withdraw the consent to receive tax-reporting
              documents electronically by sending a letter to 365egifts
              Investments email to&nbsp;
            </span>
            <a href="mailto:support@365egifts.com">
              <span style={{ fontWeight: "400" }}>support@365eGifts.com</span>
            </a>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              You have the right to request paper delivery of any communications
              that 365egifts is required by law to provide to you in paper form.
              365egifts may charge a service fee for the delivery of
              communications that would otherwise be delivered electronically.
              365egifts may terminate electronic delivery at any time.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              All communications provided to you electronically shall be deemed
              delivered to you when sent, whether actually received or not. You
              agree that 365egifts fulfills its legal obligation to deliver to
              you any document if sent via electronic delivery. You are
              responsible for timely reporting any discrepancies contained in
              the electronic communications. It is your responsibility to access
              the electronic communications and promptly review the information
              contained therein. All information contained therein shall be
              binding upon you, if you do not object in writing within
              forty-eight (48) hours after the communication is sent to you.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              If you experience any difficulty opening a document electronically
              delivered by us, you must promptly notify 365egifts within
              forty-eight (48) hours after delivery.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              You understand that to receive electronic delivery of
              notifications, you must have Internet access, a valid email
              address, and the ability to download applications and information
              and a printer if you wish to retain paper copies of the
              communications.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              All email sent to and from 365egifts is subject to archival,
              monitoring, review by, and disclosure to, someone other than the
              recipient, such as 365egift&rsquo;s compliance administrators and
              regulatory bodies.
            </span>
          </p>
          <p>
            <strong>Electronic Signature</strong>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              By electronically signing any electronic document, including but
              not limited to Customer Account Agreement, you agree that your
              electronic signature is valid evidence of your consent to be
              legally bound by such electronic document.
            </span>
          </p>
          <p>
            <strong>Intellectual Property Rights</strong>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              Trademarks and logos are the property of their respective owners
              and do not represent endorsements of any kind. Unless otherwise
              noted, 365egifts, Inc. and its affiliates are not partners,
              affiliates or licensees of these companies. Except as otherwise
              specifically provided in these Terms and Conditions, you may not
              download or save a copy of the Service or any portion thereof, for
              any purpose. You may, however, print a copy of individual screens
              appearing as part of the Service solely for your personal,
              non-commercial use or records, provided that any marks, logos or
              other legends that appear on the copied screens remain on, and are
              not removed from the printed or stored images of such screens. All
              title and intellectual property rights in and to the content of
              the Linked Sites is the property of the respective content owner
              and may be protected by applicable copyright or other intellectual
              property laws and treaties.
            </span>
          </p>
          <p>
            <strong>Restrictions on Use of Material</strong>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              Except as expressly permitted, you agree not to use the Service
              for other commercial purposes, and, you may not
              modify, copy, publish, display, transmit, adapt or in any way
              exploit the content of the Service. Only if you obtain prior
              written consent from us - and from all other entities with an
              interest in the relevant intellectual property - may you publish,
              display or commercially exploit any material from the Service. To
              seek our permission, you may write to us at 365egifts Investments
              PO Box 518 Pasadena, CA 91102. If permission is granted by us and
              by all other entities with an interest in the relevant
              intellectual property, you may not change or delete any author
              attribution, trademark, legend or copyright notice.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              You must abide by all additional copyright notices or other
              restrictions posted on the Service.
            </span>
          </p>
          <p>
            <strong>Account Access</strong>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              You will receive a user ID and password that provide electronic
              access to your Gift Cards Account, if
              you maintain one. You are solely responsible for protecting the confidentiality
              of your accounts access information. You must notify 365egifts in
              writing immediately if you discovery that the security or
              confidentiality of your Accounts access information has been
              compromised.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              If you permit a third party to access your accounts or act on your
              behalf, you do so at your own risk. 365egifts will consider any
              request made or order entered with your proper accounts access
              information to be a request or order by you. 365egifts is not
              liable for any losses or damages caused by any third person that
              you authorize or allow to access your Accounts. If you authorize
              or allow a third party to access your Accounts, you will cooperate
              with 365egifts in its efforts to defend claims by that third
              person, and will indemnify 365egifts against any liability,
              expense, loss or damage that arises from such third party access.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              365egifts may, at any time and at its exclusive discretion,
              discontinue your access to the Service and close any Accounts or
              prohibit.
            </span>
          </p>
          <p>
            <strong>User Conduct</strong>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              By using the Service you agree to represent yourself accurately
              and truthfully at all times and to provide true, accurate, current
              and complete information about yourself as prompted by the
              Service. You grant to us the right to independently verify any
              information that you post on the Service. If any information
              provided by you is untrue, inaccurate, not current or incomplete,
              365egifts reserves the right to terminate your access to the
              Service and refuse any and all current or future use of the
              Service.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              You agree that you will not harvest, collect or store information
              about the users of this Service or the content posted by others on
              this Service or use such information for any purpose inconsistent
              with the purpose of this Service or for the purpose of
              transmitting or facilitating transmission of unsolicited bulk
              electronic mail or communications.
            </span>
          </p>
          <p>
            <strong>Monitoring and Recording</strong>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              365egifts reserves the right to view, monitor, and record activity
              on our sites without notice to or permission from you. You
              authorize 365egifts to use vendors to verify your identity or
              information provided by you. Any information obtained by
              monitoring, reviewing, or recording activity on the platform is
              subject to review by law enforcement organizations in connection
              with investigation or prosecution of possible criminal activity as
              well as by any regulatory agency or self-regulatory body with
              supervisory authority over 365egifts. 365egifts will also comply
              with all U.S. federal or state court orders involving requests for
              such information.365egifts reserves the right to record all
              telephone calls, chat, and other communications to 365egifts to
              help us with the quality of our service and for other reasons that
              we deem appropriate to protect our customers or for our own
              protection.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              We are using the services of our trusted service provider in order
              to help us prevent online frauds. Our service provider may use and
              process your personal information in accordance with applicable
              privacy and data protection laws. You can read about the ways by
              which our service provider may use and process your personal
              information in this address&nbsp;
            </span>
            <a href="https://www.forter.com/service-privacy-policy/">
              <span style={{ fontWeight: "400" }}>
                https://www.forter.com/service-privacy-policy/
              </span>
            </a>
          </p>
          <p>
            <strong>Cookies</strong>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              When you visit the Service, 365egifts may collect technical and
              navigational information, such as computer browser type, IP
              address, pages visited, and average length of time spent on our
              sites. We may also record the time of actions taken on the sites
              for regulatory or other control purposes. This is accomplished
              through the use of cookies. We use cookies to allow you to avoid
              having to re-enter information and to aid in navigation between
              pages of the Service; help us recognize you as a unique user when
              you return to the Service; remember what kinds of information you
              have shown interest in while on the Service; and collect and
              compile anonymous, aggregated information for statistical and
              evaluation purposes. No personal information is stored in the
              cookies. You may be able to disable cookies by changing your
              browser settings, but the Service&rsquo; functionality and your
              experience may be diminished as a result. For more information
              about cookies and how to disable them please visit&nbsp;
            </span>
            <a href="http://www.allaboutcookies.org/">
              <span style={{ fontWeight: "400" }}>
                http://www.allaboutcookies.org
              </span>
            </a>
            <span style={{ fontWeight: "400" }}>.</span>
          </p>
          <p>
            <strong>User&rsquo;s Grant of Limited License</strong>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              By communicating with 365egifts, including submitting or sending
              content, or other information or material to 365egifts, you grant
              365egifts the royalty-free, unrestricted, world-wide, perpetual,
              irrevocable, non-exclusive and fully sub-licensable right and
              license to use, reproduce, modify, adapt, publish, translate,
              create derivative works from, distribute, perform and display such
              content (in whole or part), including any information,
              suggestions, ideas, drawings or concepts contained in such
              content, worldwide and/or to incorporate it in other works in any
              form, media, or technology now known or later developed. Such
              license includes incorporating the content you submit, in whole or
              in part, into a 365egifts feature. You will remain responsible for
              the accuracy, copyright compliance, legality, decency, or any
              other aspect of such submitted material, including any
              Contribution or part thereof, or other communication to 365egifts.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              You also warrant that any moral rights in posted materials have
              been waived.
            </span>
          </p>
          <p>
            <strong>Linked Sites</strong>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              You may be able to link to Linked Sites from the Service. Linked
              Sites are not reviewed, controlled, or examined by 365egifts in
              any way and 365egifts is not responsible for the content,
              availability, advertising, products or other materials of any such
              Linked Sites, or any additional links contained therein. These
              links do not imply 365egift&rsquo;s endorsement of or association
              with the Linked Sites. It is your sole responsibility to comply
              with the appropriate terms of service of the Linked Sites as well
              as with any other obligation under copyright, secrecy, defamation,
              decency, privacy, security and export laws related to the use of
              such Linked Sites and any content contained thereon. In no event
              shall 365egifts be liable, directly or indirectly, to anyone for
              any loss or damage arising from or occasioned by the creation or
              use of the Linked Sites or the information or material
              accessed-through these Linked Sites. You should direct any
              concerns to that site's administrator. 365egifts reserves the
              exclusive right, at its sole discretion, to add, change, decline
              or remove, without notice, any feature or link to any of the
              Linked Sites from the Service and/or introduce different features
              or links to different users.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              You must receive written permission from 365egifts for any type of
              link to the Service. To seek our permission, please write to us at
              365egifts Investments 440 N Wolfe Rd, Suite 162 Sunnyvale, CA,
              94087. We reserve the right to rescind any permission granted by
              us to link through any type of link, and to require termination of
              any such link to the Service, at our discretion at any time.
              Framing of the Service is prohibited.
            </span>
          </p>
         {/* <p>
            <strong>Parental Permission</strong>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              365egifts may require users under the age of majority to obtain
              the consent of a parent or guardian to view certain content, and
              365egifts may limit access to certain content to users under a
              specified age. You agree to abide by any such restrictions, and
              not to help anyone avoid these restrictions.
            </span>
         </p> */}
          <p>
            <strong>Privacy Policy</strong>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              We are committed to protecting your privacy and security and have
              explained in detail the steps we take to do so. We urge you to
              read our&nbsp;
            </span>
            <a href="https://www.estockgifts.com/">
              <span style={{ fontWeight: "400" }}>Privacy Policy</span>
            </a>
            <span style={{ fontWeight: "400" }}>
              , accessible from the Disclosures page on&nbsp;
            </span>
            <a href="https://www.365egifts.com/">
              <span style={{ fontWeight: "400" }}>www.365eGifts.com</span>
            </a>
          </p>
          <p>
            <strong>Disclaimer of Warranty and Liability</strong>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              ALL CONTENT, PRODUCTS AND SERVICES OBTAINED THROUGH THE SERVICE,
              INCLUDING, BUT NOT LIMITED TO GRAPHICS, TEXT AND LINKS TO OTHER
              WEBSITES, IS PROVIDED AS IS AND WITHOUT WARRANTY OF ANY KIND,
              EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO (i) ANY
              WARRANTIES CONCERNING THE AVAILABILITY, ACCURACY, APPROPRIATENESS,
              RELIABILITY, TIMELINESS, USEFULNESS, OR OTHERWISE OF THE SERVICE;
              AND (ii) ANY WARRANTIES OF TITLE, WARRANTY OF NON-INFRINGEMENT,
              WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              365EGIFTS DOES NOT REPRESENT OR WARRANT THAT YOU WILL BE ABLE TO
              ACCESS OR USE THE SERVICE AT ANY PARTICULAR LOCATION OR FOR ANY
              CONTINUOUS PERIOD OF TIME.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              IN NO EVENT WILL 365EGIFTS, ITS OFFICERS, DIRECTORS, EMPLOYEES,
              AGENTS, SPONSORS, ADVERTISERS, PARENTS, AFFILIATES, SUCCESSORS OR
              ASSIGNS, BE LIABLE TO ANY PARTY FOR ANY DIRECT, INDIRECT, SPECIAL,
              PUNITIVE OR CONSEQUENTIAL DAMAGES OR INJURY, INCLUDING, WITHOUT
              LIMITATION, ANY LOST PROFITS, BUSINESS INTERRUPTIONS, LOSS OF
              PROGRAMS OR DATA ON YOUR EQUIPMENT, CONSEQUENCES OF DECISIONS MADE
              OR ACTION TAKEN BY YOU IN RELIANCE UPON THE INFORMATION ON THE
              SERVICE, OR OTHERWISE, EVEN IF 365EGIFTS&nbsp;IS EXPRESSLY ADVISED
              OF SUCH DAMAGES, WHETHER FOR BREACH OF CONTRACT, TORT, NEGLIGENCE,
              DEFAMATION OR UNDER ANY OTHER CAUSE OF ACTION, ARISING OUT OF 1)
              ANY ERROR, OMISSION, DELETION, UNTIMELINESS, INCORRECT SEQUENCING
              OR DEFECT IN THE SERVICE; (2) ANY FAILURE OF PERFORMANCE, ERROR,
              OMISSION, INTERRUPTION, DELETION, DEFECT, DELAY IN OPERATION OR
              TRANSMISSION, COMPUTER VIRUS, COMMUNICATION LINE FAILURE, THEFT OR
              DESTRUCTION OR UNAUTHORIZED ACCESS TO, ALTERATION OF, OR USE OF
              RECORD; OR (3) ANY USE OF THE SERVICE. BECAUSE SOME STATES OR
              JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR THE LIMITATION OF
              LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, IN SUCH STATES
              OR JURISDICTIONS, 365EGIFTS'S LIABILITY SHALL BE LIMITED TO THE
              EXTENT PERMITTED BY LAW.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              YOU ASSUME THE RESPONSIBILITY TO TAKE ADEQUATE PRECAUTIONS AGAINST
              UNAUTHORIZED ACCESS OR DAMAGE TO YOUR SYSTEMS OR OPERATIONS THAT
              COULD BE CAUSED BY DEFECTS OR DEFICIENCIES IN THE SERVICE, ANY
              INFORMATION, SERVICE, SYSTEM, DATABASE OR MATERIAL PROVIDED BY
              365EGIFTS, OR PART THEREOF. YOU ALSO ACKNOWLEDGE THAT ELECTRONIC
              COMMUNICATIONS AND DATABASES ARE SUBJECT TO ERRORS, TAMPERING AND
              BREAK-INS, AND THAT WHILE 365EGIFTS&nbsp;WILL IMPLEMENT REASONABLE
              SECURITY PRECAUTIONS TO ATTEMPT TO PREVENT SUCH OCCURRENCES,
              365EGIFTS DOES NOT GUARANTEE THAT SUCH EVENTS WILL NOT TAKE PLACE.
              YOUR INSTALLATION AND INPUTS, AS WELL AS THIRD PARTY SYSTEMS AND
              PROCEDURES, MAY INFLUENCE THE OUTPUT AND ERRORS IN ANY ELECTRONIC
              TRANSMISSION OR COMMUNICATION, AND CAN RESULT IN SUBSTANTIAL
              ERRORS IN OUTPUT, INCLUDING INCORRECT INFORMATION. IN ADDITION,
              ERRORS MAY BE INTRODUCED INTO INFORMATION IN THE COURSE OF ITS
              TRANSMISSION OVER ELECTRONIC NETWORKS.
            </span>
          </p>
          <p>
            <strong>Indemnification</strong>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              Upon a request by 365egifts, you agree to defend, indemnify and
              hold 365egifts, its affiliates, officers, directors and employees
              harmless from any and all claims, liabilities, costs and expenses,
              including reasonable attorneys&rsquo; fees, arising in any way
              from or relating to (i) your use or misuse of the Service; (ii)
              your breach of any agreement with or duty to 365egifts or a third
              party; or (iii) the uploading, posting, publishing, emailing,
              reproduction, distribution or transmission of any content or other
              materials by you or users authorized by you. 365egifts reserves
              the right, at its own expense, to assume the exclusive defense and
              control of any matter otherwise subject to indemnification by you,
              in which event you will cooperate with 365egifts in asserting any
              available defense.
            </span>
          </p>
          <p>
            <strong>Limitations for International Users</strong>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              The Service may not be available in your state or country.
              365egifts does not represent that materials on the site are
              appropriate or available for use outside of the United States.
              Persons who choose to access this site from other locations do so
              on their own initiative, and are responsible for compliance with
              local laws, if and to the extent local laws are applicable.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              Nothing in the Service should be considered a solicitation of an
              offer to buy any security, advisory or other service to any person
              in any jurisdiction in which such offer or
              solicitation, purchase or sale, or provision of such services is
              illegal. References to services unavailable in your state or
              country do not imply that these services will be made available to
              you.
            </span>
          </p>
          <p>
            <strong>Notices</strong>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              You must provide written notices to 365egifts by sending an email
              to&nbsp;
            </span>
            <a href="mailto:support@365egifts.com">
              <span style={{ fontWeight: "400" }}>support@365eGifts.com</span>
            </a>
            <span style={{ fontWeight: "400" }}>
              , or sending a fax to (818) 688-0722, or by sending a letter to
              365egifts Investments 440 N Wolfe Rd, Suite 152 Sunnyvale, CA,
              94087
            </span>
          </p>
          <p>
            <strong>Authority and Reliance on Your Instructions</strong>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              You represent that (a) you are of legal age in the state or
              jurisdiction in which you reside; (b) you have full power and
              authority to enter into and perform your obligations under this
              Terms and Conditions; (c) all information provided by you in
              connection with the service is true and correct, and 365egifts may
              rely on the truthfulness and correctness of that information.
            </span>
          </p>
          <p>
            <strong>Applicable Laws and Jurisdiction</strong>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              You agree that the laws of the State of California, excluding its
              conflicts-of-law rules, shall govern these Terms and Conditions.
              Please note that your use of the Service may be subject to other
              local, state, national, and international laws. You expressly
              agree that exclusive jurisdiction for any claim or dispute with
              365egifts or relating in any way to your use of the Service
              resides in the federal and state courts in Los Angeles County,
              California, and you further agree and expressly consent to the
              exercise of personal jurisdiction in the courts of the State of
              California, in connection with any such dispute and including any
              claim involving 365egifts or its affiliates, subsidiaries,
              employees, contractors, officers, directors, telecommunication
              providers and content providers, to the extent that such dispute
              is not subject to mandatory arbitration as set forth under the
              Customer Account Agreement.
            </span>
          </p>
          <p>
            <strong>Provisions Unenforceable or Invalid</strong>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              If any part of these Terms and Conditions is held invalid or
              unenforceable, that portion shall be construed in a manner
              consistent with applicable law to reflect, as nearly as possible,
              the original intentions of the parties, and the remaining portions
              shall remain in full force and effect.
            </span>
          </p>
          <p>
            <strong>Assignment</strong>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              You shall not transfer, assign, sublicense nor pledge in any
              manner whatsoever, any of your rights or obligations under these
              Terms and Conditions. 365egifts may transfer, assign sublicense or
              pledge in any manner whatsoever, any of its rights and obligations
              under these Terms and Conditions to a subsidiary, affiliate, or
              successor thereof or to any third party whatsoever, without
              notifying you or receiving your consent.
            </span>
          </p>
          <p>
            <strong>Application of Other Agreements</strong>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              Other disclosures, terms and conditions apply to the use of your
              Service. You agree to be legally bound by
              all written agreements between you and 365egifts, including, but
              not limited to, the Customer Account Agreement and all agreements
              and disclosures on 365egift&rsquo;s Disclosure page located
              at&nbsp;
            </span>
            <a href="https://www.365egifts.com/">
              <span style={{ fontWeight: "400" }}>www.365eGifts.com</span>
            </a>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              If you have any questions about these Terms and Conditions or the
              Service in general, please email us at&nbsp;
            </span>
            <a href="mailto:support@365egifts.com">
              <span style={{ fontWeight: "400" }}>support@365Ggifts.com</span>
            </a>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              ADDITIONAL TERMS AND CONDITIONS FOR GIFT CARD PURCHASERS
            </span>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              Here is some important information about your purchase of a Gift
              Card. If you have a question, please send an email to&nbsp;
            </span>
            <a href="mailto:support@365egifts.com">
              <span style={{ fontWeight: "400" }}>support@365eGifts.com</span>
            </a>
          </p>
          <p>
            <strong>Face Value and Fulfillment Fee</strong>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              A 365egifts Gift Card is a convenient way to give a gift to
              family and friends. When you buy a Gift Card, you will choose a
              face value (for example, $50). Gift Cards may be purchased in any
              face value up to $ 1,000.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              The total price you pay for a Gift Card will be the face value
              plus a fulfillment fee that depends on the face value. We&rsquo;ll
              let you know what the fee is when you&rsquo;re choosing your face
              value to avoid any surprises at checkout.
            </span>
          </p>
          <p>
            <strong>Personalizing Your Gift</strong>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              In addition to choosing a face value, you can personalize your
              gift with  themes and send a message along with it you think your 
              recipient might like. The Gift Card is not, however, redeemable for
              cash or refundable.
            </span>
          </p>
          <p>
            <strong>Paying for Your Gift Card</strong>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              We accept Mastercard, VISA, American Express, Discover and PayPal.
              Once you complete your purchase, you will receive an email receipt
              of the Gift Card.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              365egifts accepts credit/debit cards and PayPal for the
              convenience of our users for the purpose of purchasing a Gift
              Card. Please note that many credit cards charge interest when you
              carry a monthly balance, but may not charge interest if you pay
              the outstanding balance by the payment due date. 365egifts does
              not allow cash advances, because they typically incur immediate
              interest charges. Please contact your credit card company if
              you&rsquo;re not sure how interest is calculated for your card.
            </span>
          </p>
         {/* <p>
            <strong>Gift Delivery Options</strong>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              You can have us email it to the recipient, or you can print a
              hardcopy and give it to the recipient yourself. We have option to
              print physical card and ship to sender or recipient address.
            </span>
         </p> */}
          <p>
            <strong>Claiming the Gift</strong>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              To claim your gift, your recipient will:
            </span>
          </p>
          <ul>
            <li>
              <span style={{ fontWeight: "400" }}>
                Click on a link in the Gift Card (if she received it by email),
                or visit&nbsp;
              </span>
              <a href="https://www.365egifts.com/fe/redeem">
                <span style={{ fontWeight: "400" }}>www.365eGifts.com</span>
              </a>
              <span style={{ fontWeight: "400" }}>
                and enter the code that&rsquo;s on the Gift Card.
              </span>
            </li>
            <li>
              <span style={{ fontWeight: "400" }}>
                Decide whether she wants to stick with the gift you suggested or
                switch to something else 
              </span>
            </li>
            <li>
              <span style={{ fontWeight: "400" }}>
                Login to her Gift Card Account, or open one if she does not
                already have one.
              </span>
            </li>
          </ul>
          <p>
            <span style={{ fontWeight: "400" }}>
              The recipient will need to have a valid email address to open a
              Gift Card Account.
            </span>
          </p>
          <p>
            <strong>Purchasing&nbsp;365eGifts</strong>
            <span style={{ fontWeight: "400" }}>&nbsp;</span>
            <strong>Gift Cards from Unauthorized or Third Party Website</strong>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              We advise you to exercise caution with unauthorized or third-party
              websites selling 365eGifts Gift Cards, especially at a discount,
              as their offers carry a high risk of fraud. 365eGifts reserves the
              right to reject the redemption or honor the redemption value of
              any such gift card. If in doubt, please contact customer service
              at&nbsp;
            </span>
            <a href="mailto:support@365egifts.com">
              <span style={{ fontWeight: "400" }}>support@365eGifts.com</span>
            </a>
            <span style={{ fontWeight: "400" }}>
              &nbsp;prior to making your purchase.
            </span>
          </p>
         {/* <p>
            <strong>Recipient&rsquo;s Investments Account</strong>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              If user wants to redeem her Gift Card, the recipient
              will be invited to open an Investments Account with 365eGifts
              Investments, Inc. The recipient will need to provide certain
              information required by Federal law to open an Investment Account,
              including her name, address, contact information, date of birth,
              and Social Security number. Unlike a bank account, an Investments
              Account is not FDIC insured.
            </span>
         </p> */}
         {/* <p>
            <strong>Recipients Who Are Under 18</strong>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              We are delighted to bring stock ownership to everyone. If a minor
              would like to redeem a Gift Card for stock, he just needs to have
              an adult on the Investments Account with him. He can even set up
              his own log-in credentials for read-only access, so he can keep
              track of his Investments Account.
            </span>
          </p>
          <p>
            <strong>Conversion into Stock</strong>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              If you personalize your Gift Card with the name of a stock, the
              face value of the Gift Card will stay fixed, and will not
              fluctuate with the market, because the Gift Card is not a
              security. If the recipient decides she wants stock when she
              redeems the Gift Card, she will get an amount of stock based on
              the face value of the Gift Card and the market on close price of
              the stock at the end of the trading day. For example, if the Gift
              Card face value is $50 and the market on close price is $100, the
              recipient will receive 0.5 shares ($50/$100).
            </span>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              Once she receives her stock, its value will fluctuate with the
              market, which means there is a potential for gains and a risk of
              loss. If the stock price goes up (or down), the value of the
              recipient&rsquo;s stock will go up (or down). She will be able to
              monitor the value of her stock by logging in to her Investments
              Account. She also will be able to sell her stock, buy more stock,
              or buy or sell other stock.
            </span>
          </p> */}
          <p>
            <strong>Reminders</strong>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              If two weeks have gone by and the recipient hasn&rsquo;t claimed
              her gift, we will send you (and the recipient, if we emailed the
              Gift Card to her) a reminder unless you ask us not to.
            </span>
          </p>
          <p>
            <strong>Refund Policy</strong>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              Gift Cards are non-refundable, but you always have the option of
              claiming the Gift Card yourself or giving it to someone else.
            </span>
          </p>
          <p>
            <strong>Misuse of Gift Cards</strong>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              365eGifts&nbsp;Gift Cards are not intended to be used as a way to
              transfer money from one person to another. 365eGifts will monitor
              transactions for such activity or any other activity that may
              constitute misuse of a Gift Card or is potentially suspicious or
              illegal, and reserve the right to coordinate and share information
              as needed to ensure transactional and system integrity.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              ADDITIONAL TERMS AND CONDITIONS FOR GIFT CARD RECIPIENTS
            </span>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              Congratulations on receiving a 365eGifts Gift Card! Here is some
              important information about your Gift Card. If you have any
              questions, please send an email to&nbsp;
            </span>
            <a href="mailto:support@365egifts.com">
              <span style={{ fontWeight: "400" }}>support@365eGifts.com</span>
            </a>
          </p>
          <p>
            <strong>Claiming your Gift</strong>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              To get your gift, all you have to do is:
            </span>
          </p>
          <ul>
            <li>
              <span style={{ fontWeight: "400" }}>
                Click on the link in your Gift Card (if you received it by
                email), or visit&nbsp;
              </span>
              <a href="https://www.365egifts.com/fe/redeem">
                <span style={{ fontWeight: "400" }}>www.365eGifts.com</span>
              </a>
              <span style={{ fontWeight: "400" }}>
                and enter the code that&rsquo;s on the Gift Card.
              </span>
            </li>
            <li>
              <span style={{ fontWeight: "400" }}>
                Decide whether you want to stick with the gift the purchaser
                chose, or switch to something else.
              </span>
            </li>
            <li>
              <span style={{ fontWeight: "400" }}>
                Login to her Gift Card Account, or open one if she does not
                already have one.
              </span>
            </li>
          </ul>
          <p>
            <span style={{ fontWeight: "400" }}>
              The recipient will need to have a valid email address to open a
              Gift Card Account.
            </span>
          </p>
          <p>
            <strong>Valid Email Address and phone number</strong>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              You will need to have a valid email address to open a Gift Cards 
              Account. It is your responsibility to keep your email address
              up to date to ensure that we can reach you if needed.
            </span>
          </p>
          {/*<p>
            <strong>Your Investments Account</strong>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              If you decide to redeem your Gift Card, you will be
              invited to open an Investments Account with 365eGifts Investments,
              Inc.
            </span>
          </p> */}
          {/*<p>
            <strong>You Don&rsquo;t Have to Be an Adult</strong>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              We are delighted to bring stock ownership to everyone. If you
              would like to redeem your Gift Card for stock, you just need to
              have an adult on the Investments Account with you. You can even
              set up your own log-in credentials for read-only access, so you
              can keep track of your Investments Account.
            </span>
          </p> */}
          {/*<p>
            <strong>Conversion into stock</strong>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              If you decide to redeem your Gift Card for stock, you will get an
              amount of stock based on the face value of the Gift Card and the
              market on close price of the stock at the end of the trading day.
              For example, if the Gift Card face value is $50 and the market on
              close price is $100, you will receive 0.5 shares ($50/$100).
            </span>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              Once you receive your stock, its value will fluctuate with the
              market, which means there is a potential for gains and a risk of
              loss. If the stock price goes up (or down), the value of your
              stock will go up (or down). You will be able to monitor the value
              of your stock by logging in to your Investments Account. You also
              will be able to sell your stock, buy more stock, or buy or sell
              other stock.
            </span>
          </p> */}
          <p>
            <strong>Misuse of Gift Cards</strong>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              Gift Cards are not intended to be used as a way to transfer money
              from one person to another.365eGifts and 365eGifts Investments
              will monitor transactions for such activity or any other activity
              that may constitute misuse of a Gift Card or is potentially
              suspicious or illegal, and reserve the right to coordinate and
              share information as needed to ensure transactional and system
              integrity. 365eGiftsreserves the right to change any of the above
              terms and conditions to protect its customers and the integrity of
              its business, or as required by law.
            </span>
          </p>
         {/* <p>
            <span style={{ fontWeight: "400" }}>
              ADDITIONAL TERMS AND CONDITIONS FOR 365eGifts INVESTMENTS ACCOUNTS
            </span>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              If you open and/or maintain an Investments Account, the following
              terms and conditions also apply:
            </span>
         </p> */}
      {/*    <p>
            <strong>Risks of Trading and Investments</strong>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              365eGifts.com is a financial portal of 365eGifts, Inc., which also
              provides access to 365eGifts Investment, Inc.&rsquo;s trading
              services. 365eGifts, Inc. is not a registered broker-dealer
            </span>
      </p> */}
        {/*  <p>
            <strong>
              Securities products are: Not FDIC insured &middot; Not bank
              guaranteed &middot; May lose value
            </strong>
          </p>
          <p>
            <strong>Content</strong>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              The content of the Service, including research, tools and
              securities symbols, is for educational and informational purposes
              and should not be intended as a recommendation or solicitation to
              engage in any particular securities transaction or investment
              strategy. You alone are responsible for evaluating the benefits
              and risks associated with the use of the Service or products and
              to decide which securities and strategies better suit your
              financial situation and goals, risk profile, etc. The projections
              regarding the probability of investment outcomes are hypothetical
              and not guaranteed for accuracy or completeness. They do not
              reflect actual investment outcomes and are not guarantees of
              future results. Projections and tools' calculations do not take
              into consideration commissions and other costs that will impact
              investment outcomes. All investments involve risk. Losses may
              exceed the principal invested. Past performance of a security,
              market, or financial product does not guarantee future results.
              365eGifts and does not offer tax, legal or financial advice.
              Content on these websites may be out of date or time-sensitive.
              Content is subject to change or removal without notice.
            </span>
          </p>
          <p>
            <strong>Your Responsibilities as an Investor</strong>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              You are a self-directed investor who is individually responsible
              for determining the suitability of your investment decisions.
              365eGifts is not responsible for the investment decisions made by
              you or on your behalf. 365eGifts is not responsible for the
              strategies, actions or inactions taken with respect to your
              Investments Account. 365eGifts is not responsible for the gains or
              losses you incur. The orders you place and instructions you give
              to 365eGifts will be unsolicited. The employees, agents and
              representatives of 365eGifts are not authorized to give you
              investment advice, and any instructions you receive from 365eGifts
              with respect to Investments Accounts or Service will be limited to
              technical or administrative guidance. You understand that the
              market values of securities fluctuate. You recognize that numerous
              independent resources are available from which you can obtain
              information about issuers, securities, exchanges, industries, and
              any other factor that might influence a reasonable investment
              decision. You acknowledge that you utilize the Service at your own
              risk, including the investment and analytical tools it makes
              available to you.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              You are responsible for promptly reviewing all orders, executions
              and Investments Account statements in connection with your
              Investments Account. You are responsible for notifying 365eGifts
              immediately in writing of any unauthorized use or unusual activity
              with respect to your Investments Account, which may include, but
              is not limited to, failure to receive accurate confirmation of a
              transaction, receipt of confirmation of a transaction you did not
              place or the discovery of any inaccurate information relating to
              your Investments Account. Further, you are responsible for
              inputting accurate trading information, and before entering
              additional orders, you are responsible for knowing the status of
              any orders that are pending. Unless you notify 365eGifts of a
              discrepancy within ten (10) days after the delivery of a
              confirmation or Investments Account statement, it will be deemed
              to have been accepted by you as delivered. Any oral communications
              regarding inaccuracies or discrepancies should be reconfirmed in
              writing to protect your rights, including those under the
              Securities Investor Protection Act (or SIPA). For discrepancies
              related to electronic fund transfers, please contact your
              financial institution. You agree that 365eGifts will not be liable
              to you for any losses arising in connection with your delay in
              properly reporting an error, including but not limited to, losses
              resulting from market fluctuations.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              You assume individual responsibility for determining the
              suitability of all investment decisions and strategies you make or
              implement. You must base your investment decisions upon all
              information reasonably available to you and your own assessment of
              risks and rewards. You are solely responsible for knowing the
              rights and terms for all securities purchased, sold and/or
              maintained in your Investments Account. You must educate yourself
              regarding any rights that could expire with respect to a security
              investment unless you take specific action. You must know the
              expiration dates and redemption dates relevant to your
              investments. You must know the circumstances under which rights
              associated with your investments may be called, cancelled or
              modified. You must provide365eGifts with appropriate instructions
              before a security investment in your Investments Account expires
              worthless or is redeemed for less than its fair market value;
              otherwise, 365eGifts may, at its discretion, sell the security and
              credit your Investments Account with the proceeds.
            </span>
          </p>
          <p>
            <strong>Investments Accounts</strong>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              You have a duty to provide 365eGifts with accurate Investments
              Account information and to notify 365eGifts in writing immediately
              if there are any changes to the relevant information associated
              with your Investments Account. This includes, but is not limited
              to, changes in your name, email address, mailing address,
              telephone number, taxpayer identification number or backup
              withholding requirements. You also must notify 365eGifts
              immediately if you are or become employed or affiliated with a
              stock brokerage firm, bank, trust company, insurance company or
              securities exchange, or if you are or become an officer, director,
              10% shareholder or affiliate of a publicly traded company.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              365eGifts&nbsp;does not open or maintain accounts for residents of
              foreign countries. Foreign citizens with a social security number
              and legally residing in the USA may open an Investments Account.
              365eGifts reserves the right to refuse the opening of any given
              Investments Account at any time.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              All accounts are cash accounts, which means that all purchases
              must be paid for with settled funds, and 365eGifts does not
              provide credit or margin lending to you. All securities, assets
              and other property held in your Investments Account are subject to
              a lien in favor of 365eGifts for the payment of all trades, debit
              balances, margin calls or other obligations arising in connection
              with your Investments Account. You grant 365eGifts your express
              legal authority to use, liquidate and/or transfer any and all
              securities, assets or other property in your Investments Account
              as needed to satisfy any indebtedness arising in connection with
              your Investments Account, to the extent permitted by law. You or
              365eGifts may close your account at any time. You will, however,
              remain responsible for all debts or obligations to 365eGifts and
              pending transactions.
            </span>
          </p> */}
          {/* <p>
            <strong>Orders and Execution</strong>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              365eGifts&nbsp;Investments may aggregate orders and execute trades
              at pre-set times. Stock orders received before 3:00 pm ET may be
              aggregated and executed that day, and orders received after 3:00
              pm ET may be executed the following trading day. There is no time
              for Crypto trade, user can trade any time. therefore, the price
              you will receive may differ from the market price at the time your
              order was placed. This may increase market risk in a volatile
              market. Securities purchases may include fractional shares. You
              understand that it is likely that you will receive fractional
              shares in stock purchases and that you will only be able to vote
              your whole shares and not fractional shares. In addition, if you
              transfer your Investments Account to another financial
              institution, you may not be able to transfer the fractional
              shares, but will instead need to sell those fractional shares and
              transfer the proceeds.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              Each stock available on the 365eGifts platform may have a maximum
              amount for purchases. Limit orders for stocks are not accepted on
              the Service, meaning you may not set a share price ahead of time
              at which a purchase or sale is triggered.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              Freeriding, which includes making purchases using the proceeds of
              invalid deposits or selling positions that were purchased with
              unsettled funds, is prohibited.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              Placing an order with 365eGifts does not guarantee that it can be
              executed, and 365eGifts is not responsible if an order cannot be
              executed according to your instructions. 365eGifts is not
              responsible for unexecuted orders due to communication failures.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              Once you have placed on order online, there is no guarantee that
              365eGifts will honor a subsequent request to cancel or modify that
              order. 365eGifts is not liable to you if it does not cancel or
              modify an order placed by you or on your behalf.
            </span>
          </p>
          <p>
            <strong>Quotes</strong>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              Any price quotes may be delayed fifteen (15) minutes or longer,
              according to the rules and regulations applicable to exchanges and
              quote providers. 365eGifts does not make any representations,
              warranties or other guarantees as to the accuracy or timeliness of
              any price quotes.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              365eGifts&nbsp;may provide you information regarding the value of
              your share positions based on the share price at the close of the
              latest market day; the data is for informational purposes only and
              does not reflect the price you may receive if you sell your
              shares. You also acknowledge that the quotes, and information
              provided based on quotes, are obtained from sources that 365eGifts
              believes to be reliable, but that 365eGifts does not guarantee the
              accuracy and completeness of those quotes or information. In
              addition, you represent that you will use the quotes solely for
              your own personal use related to your Investments Account or
              activity and not for professional use. You waive any claim that
              you might have against 365eGifts or the quote provider related to
              the quotes or the quote-based information on the Service.
            </span>
          </p>
          <p>
            <strong>Real-Time Investments Account Information</strong>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              Real-time information about your Investments Account, including
              without limitation positions and order status is non-binding upon
              365egifts, it agents and assigns. Such information may be changed
              based on market corrections, resolution of discrepancies and other
              factors.
            </span>
          </p>
          <p>
            <strong>Sila Terms of Service (TOS)</strong>
            <span style={{ fontWeight: "400" }}>
              <br />
            </span>
            <span style={{ fontWeight: "400" }}>
              Electronic Fund Transfers (&ldquo;EFTs&rdquo;) and Account
              Balances. By creating a Keyutech &nbsp;Account and initiating bank
              deposits or withdrawals (i.e., EFTs), you agree to the Terms of
              Service and Privacy Policy of our financial software provider,
              Sila Inc. (together, the &ldquo; Sila ToS &rdquo;).
              factors&nbsp;&nbsp;must comply with the Sila ToS when creating or
              using your Keyutech (
            </span>
            <a href="http://www.365egifts.com/">
              <span style={{ fontWeight: "400" }}>www.365egifts.com</span>
            </a>
            <span style={{ fontWeight: "400" }}>
              ) Account. The Sila ToS may be modified from time to time, and the
              governing version is incorporated by reference into these Terms of
              Service.
            </span>
            <span style={{ fontWeight: "400" }}>
              <br />
            </span>
            <span style={{ fontWeight: "400" }}>
              IT IS YOUR RESPONSIBILITY TO READ AND UNDERSTAND THE SILA TOS
            </span>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              BECAUSE IT CONTAINS TERMS AND CONDITIONS CONCERNING Keyutech
              ACCOUNT, INCLUDING BUT NOT LIMITED TO LIMITATIONS, REVERSAL,
            </span>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              AND ARBITRATION PROVISIONS, AND YOUR RELEVANT RIGHTS
              AND&nbsp;LIABILITIES.
            </span>
          </p>
          <p>
            <strong>
              Questions or Complaints Related to Your Investments Account
            </strong>
          </p>
          <p>
            <span style={{ fontWeight: "400" }}>
              Customer complaints related to your Investments Account should be
              directed to 365eGifts'sCustomer Service at 440 N Wolfe Rd Suite
              152 Sunnyvale, CA, 94087 or by emailing customer service at&nbsp;
            </span>
            <a href="mailto:support@365egifts.com">
              <span style={{ fontWeight: "400" }}>support@365eGifts.com</span>
            </a>
            <span style={{ fontWeight: "400" }}>.</span>
              </p> */}
          <p>
            <br />
            <br />
          </p>
        </Modal.Body>
      </Modal>
    </>
  );
}
