import React from "react";
//import Header from "./container/Header";
//import "../public/assets/assets/css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Routes from "./Routes/Routes";

import { GoogleOAuthProvider } from '@react-oauth/google';

function App() {
  return (
    <div className="App">
      {/* <Header /> */}
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>

      <ToastContainer limit={1} />
      <Routes />
      </GoogleOAuthProvider>
    </div>
  );
}

export default App;
