import React from "react";
import { Route, Link } from "react-router-dom";
// A wrapper for <Route> that Navigates to the login
// screen if you're not yet authenticated.

export function PublicRoutes({
  children,
  roles,
  middlewares,
  fallback,
  ...rest
}) {
  return (
    <>
      <Route
        {...rest}
        render={() =>
          rest.value === null ? (
            <>{children}</>
          ) : (
            <Link
              to={{
                pathname: "/",
              }}
            />
          )
        }
      />
    </>
  );
}
