import React, { useEffect, useState } from "react";
import ProfileImg from "../assets/media/profile.png";
import AImg from "../assets/media/Profile-PNG-File.png";
import CheckImg from "../assets/media/profile-check.png";
import defaultImg from "../assets/media/default_img.png";
import LogoutImg from "../assets/media/logout.png";
import { Storage } from "../Storage/Storage";
import { useHistory } from "react-router-dom";
import AuthService from "../service/AuthService";

const REACT_APP_API_IMAGE = process.env.REACT_APP_API_IMAGE;

const Profile = ({ showSidebar, hide, onEditProfileChange }) => {
  const history = useHistory();
  const prifill = Storage.get("token");
  const [profileInfo, setProfileInfo] = useState();
  const [cardProfile, setCardProfile] = useState();
  const [userProfile, setUserProfile] = useState();

  // const pathname = window.location.pathname;

  // localStorage.getItem("pwd");
  useEffect(() => {
    if (localStorage.getItem("token")) getProfileInfo();
  }, [showSidebar]);

  // console.log("userProfile", REACT_APP_API_IMAGE + userProfile)

  const getProfileInfo = () => {
    AuthService.getProfile()
      .then((res) => {
        setProfileInfo(res.data);
        setCardProfile(res);
        setUserProfile(res.data?.profile_pic);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };
  const Logout = () => {
    localStorage.removeItem("temp");
    localStorage.removeItem("byforyourData");
    localStorage.removeItem("initialData");
    localStorage.removeItem("gitafriendData");
    localStorage.removeItem("initialDatagift");
    history.push("/");
    Storage.remove("token");
  };

  return (
    <>
      <div
        id="mySidbar"
        className={
          showSidebar
            ? "sidenav profile-reset active"
            : "sidenav sidenav profile-reset "
        }
      >
        <div className="right-card-rht">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h4 className="mb-0">Profile</h4>
            <a
              style={{ cursor: "pointer" }}
              className="closebtn profile-navbar "
              onClick={() => hide(false)}
            >
              &times;
            </a>
          </div>
          <div className="d-flex align-items-center">
            <div className="profile-set-pic">
              <div className="profile-pic-set">
                {userProfile && userProfile != "NULL" ? (
                  <img
                    src={REACT_APP_API_IMAGE + userProfile}
                    className="rounded-circle"
                    height={70}
                  />
                ) : (
                  <img
                    src="/fe/assets/images/Profile-PNG-File.png"
                    className="rounded-circle"
                    height={79}
                  />
                )}
              </div>
            </div>

            <div className="ms-2">
              <div className="d-flex">
                <h4>{profileInfo && profileInfo.first_name}</h4>
                <span className="mt-1 ms-2">
                  <img src={CheckImg} />
                </span>
              </div>
              <p className="p-0 m-0" style={{ color: "#AAAAAA" }}>
                {profileInfo && profileInfo.email}
              </p>
            </div>
          </div>
          <div className="d-flex ">
            <div className="w-50 mt-3">
              <h3>{cardProfile && cardProfile.card_purchased}</h3>
              <p style={{ color: "#AAAAAA" }}>Card Purchased</p>
            </div>
            <div className="w-50 mt-3">
              <div>
                <h3>0</h3>
                <p style={{ color: "#AAAAAA" }}>Rewards Received</p>
              </div>
            </div>
          </div>

          <a
            onClick={() => onEditProfileChange()}
            className=" btn btn-theme profile-setting-navbar "
            style={{ marginRight: "27px" }}
          >
            Edit Profile
          </a>
          <a
            onClick={() => Logout()}
            href=""
            className="signup-nav btn-transparent text-primary me-3"
          >
            <img
              src={LogoutImg}
              width="20px"
              className="ms-3 me-1"
              style={{ paddingBottom: "3px" }}
            />
            <span>Log Out</span>
          </a>
        </div>
      </div>
    </>
  );
};
export default Profile;
