import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { PrivateRoutes } from "../Routes/PrivateRoutes";
import { PublicRoutes } from "../Routes/PublicRoutes";
import Header from "../container/Header";
import Login from "../Auth/Login";
import Register from "../Auth/Register";
import Buy_for_yourself from "../container/Buy_for_yourself";
import Gift_a_friend from "../container/Gift_a_friend";
import Order_Summary from "../container/Order_Summary";
import Order_History from "../container/Order_History";
import PaymentRedirect from "../container/PaymentRedirect";
import ProtectedRoutes from "./ProtectedRoutes";

const Routes = () => {
  // const REACT_APP_BASE_PATH = process.env.REACT_APP_BASE_PATH
  return (
    // <Router basename={process.env.REACT_APP_BASE_PATH} >
    <Router basename={process.env.REACT_APP_BASE_PATH}>
      <PublicRoutes exact={true} path="/" component={Header} />

      <PublicRoutes path="/login" component={Login}>
        <Login />
      </PublicRoutes>

      <PublicRoutes path="/register" component={Register}>
        <Register />
      </PublicRoutes>

      <PrivateRoutes path="/buyforyourself" component={Buy_for_yourself} />

      <PrivateRoutes path="/giftafriend" component={Gift_a_friend} />

      <PrivateRoutes path="/ordersummary/:id" component={Order_Summary} />

      <PrivateRoutes path="/orderhistory" component={Order_History} />

      {/* <PrivateRoutes path="/payment_redirect" component={PaymentRedirect} /> */}
      <Route exact path="/payment_redirect">
        <ProtectedRoutes component={PaymentRedirect} />
      </Route>
    </Router>
  );
};

export default Routes;
