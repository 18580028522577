import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import AImg from "../assets/media/Profile-PNG-File.png";
import leftimg from "../assets/media/left-img.png";
import { useHistory } from "react-router-dom";
import Register from "../Auth/Register";
import Login from "../Auth/Login";
import VarifyemailOTP from "../Auth/Varify_email_OTP";
import ForgotPassword from "../Auth/ForgotPassword";
import AuthModel from "../Auth/AuthModel";
import ResetPassword from "../Auth/ResetPassword";
import Profile from "../Auth/Profile";
import EditProfile from "../Auth/EditProfile";
import { Storage } from "../Storage/Storage";
import AuthService from "../service/AuthService";

const REACT_APP_API_IMAGE = process.env.REACT_APP_API_IMAGE;

const CommonHeader = () => {
  const [loading, setLoading] = useState(false);
  const [sideBar, setSideBar] = useState(false);
  const [LoginsideBar, setLoginSideBar] = useState(false);
  const [profile, setProfile] = useState(false);
  const [Editprofile, setEditProfile] = useState(false);
  const [EmailOTP, setEmailOTPSideBar] = useState(false);
  const [Forgotpassword, setForgotPassword] = useState(false);
  const [ForgotAuth, setForgotAuth] = useState(false);
  const [Resetpassword, setResetPassword] = useState(false);
  const [profileInfo, setProfileInfo] = useState();
  const [userProfile, setUserProfile] = useState();
  const [term, setTerm] = useState();
  const history = useHistory();
  const prifill = Storage.get("token");
  const submitHandler = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    if (localStorage.getItem("token")) getProfileInfo();
  }, []);

  const getProfileInfo = () => {
    AuthService.getProfile()
      .then((res) => {
        setProfileInfo(res.data);
        setUserProfile(res.data.profile_pic);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  const showRegisterModal = () => {
    setSideBar(true);
    setLoginSideBar(false);
  };
  const showLoginModal = () => {
    setLoginSideBar(true);
    setSideBar(false);
  };
  const showProfile = () => {
    setProfile(true);
    setSideBar(false);
  };
  const showEditProfile = () => {
    setEditProfile(true);
    setSideBar(false);
    setProfile(false);
  };
  const ShowForgotPassword = () => {
    setSideBar(false);
    setForgotPassword(true);
  };
  const showForgotAuth = () => {
    setSideBar(false);
    setForgotAuth(true);
  };
  const showResetPassword = () => {
    setResetPassword(true);
    setSideBar(false);
  };
  const ShowEmailOTPModeal = () => {
    setEmailOTPSideBar(true);
    setSideBar(false);
  };

  return (
    <>
      <div className="top-hdr">
        <nav className="navbar navbar-expand-lg navbar-light bg-light white">
          <div className="container-fluid">
            <a
              className="navbar-brand"
              onClick={() => history.push("/")}
              style={{ cursor: "pointer" }}
            >
              <img src={leftimg} />
              <p className="Serving">-Serving 24/7-</p>
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav m-auto mb-2 mb-lg-0 itms">
                <form className="d-flex justify-content-center"></form>
              </ul>
              {prifill === false ? (
                <div className="d-flex align-items-center">
                  <span
                    onClick={() => showRegisterModal()}
                    className="signup-nav btn btn-transparent me-3"
                  >
                    Sign Up
                  </span>

                  <span
                    onClick={() => showLoginModal()}
                    className="signin-nav btn btn-theme"
                  >
                    Login
                  </span>
                </div>
              ) : (
                <div className="d-flex align-items-center">
                  <span
                    className="btn btn-secondary ordr-btn"
                    onClick={() => history.push("/orderhistory")}
                  >
                    Order History
                  </span>
                  <span
                    onClick={() => showProfile()}
                    className="profile-pic profile-navbar me-3"
                    style={{ cursor: "pointer" }}
                  >
                    {userProfile && userProfile != "NULL" ? (
                      <img src={REACT_APP_API_IMAGE + userProfile} />
                    ) : (
                      <img src="/fe/assets/images/Profile-PNG-File.png" />
                    )}
                  </span>
                  <span
                    style={{ cursor: "pointer" }}
                    className="john-txt"
                    onClick={() => showProfile()}
                  >
                    {profileInfo && profileInfo.first_name}
                  </span>
                </div>
              )}
            </div>
          </div>
        </nav>
      </div>

      {/* <div className="top-hfr-two">
        <div className="tab-menu">
          <ul>
            <li>
              <a
                onClick={() => history.push("/")}
                className="tab-a active-a"
                data-id="tab1"
                style={{ cursor: "pointer" }}
              >
                Show all
              </a>
            </li>
            <li>
              <a href="#" className="tab-a" data-id="tab2">
                365eGifts
              </a>
            </li>
            <li>
              <a href="#" className="tab-a" data-id="tab3">
                Crypto Gift card
              </a>
            </li>
            <li>
              <a href="#" className="tab-a" data-id="tab4">
                Healthy and Beauty
              </a>
            </li>
            <li>
              <a href="#" className="tab-a" data-id="tab5">
                Home Care
              </a>
            </li>
            <li>
              <a href="#" className="tab-a" data-id="tab6">
                Online Shopping
              </a>
            </li>
            <li>
              <a href="#" className="tab-a" data-id="tab7">
                Food Wine
              </a>
            </li>
            <li>
              <a href="#" className="tab-a" data-id="tab8">
                Travel
              </a>
            </li>
            <li>
              <a href="#" className="tab-a" data-id="tab9">
                Sports Fitness
              </a>
            </li>
            <li>
              <a href="#" className="tab-a" data-id="tab10">
                Kids
              </a>
            </li>
            <li>
              <a href="#" className="tab-a" data-id="tab11">
                Featured
              </a>
            </li>
            <li>
              <a href="#" className="tab-a" data-id="tab12">
                Fashion
              </a>
            </li>
          </ul>
        </div>
      </div> */}
      {/* <Header/> */}
      <Register
        showSidebar={sideBar}
        onsideChnage={showLoginModal}
        onsideChnageEmail={() => ShowEmailOTPModeal()}
        hide={setSideBar}
      />

      <Login
        showSidebar={LoginsideBar}
        onsideChnage={showRegisterModal}
        onForgotChange={() => ShowForgotPassword()}
        hide={setLoginSideBar}
      />
      <ForgotPassword
        showSidebar={Forgotpassword}
        onsideChnage={() => showLoginModal()}
        onAuthChange={() => showForgotAuth()}
        hide={setForgotPassword}
      />
      <Profile
        showSidebar={profile}
        onProfileChange={() => showProfile()}
        onEditProfileChange={() => showEditProfile()}
        hide={setProfile}
      />
      <EditProfile
        showSidebar={Editprofile}
        onEditProfileChange={() => showEditProfile()}
        onProfileChange={() => showProfile()}
        hide={setEditProfile}
      />
      <VarifyemailOTP
        showSidebar={EmailOTP}
        hide={setEmailOTPSideBar}
        onLoginChange={showLoginModal}
      />

      <AuthModel
        showSidebar={ForgotAuth}
        onResetPassword={() => showResetPassword()}
        hide={setForgotAuth}
      />
      <ResetPassword showSidebar={Resetpassword} hide={setResetPassword} />
    </>
  );
};

export default CommonHeader;
